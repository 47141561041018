import React, {useState} from "react";
import useInputs from "../../../hooks/useInputs";
import {useSnackbar} from "notistack";
import ItemUtils from "../../../utils/ItemUtils";
import Page from "../../../components/Page";
import GenericInputLayout from "../../../layouts/GenericInput/GenericInputLayout";
import {InstructorEnrollmentInputs} from "./InstructorEnrollmentInputs";
import {DeleteEnrollment, UpdateEnrollment} from "../../../api/EnrollmentApi";
import {CreatePendingEnrollment, DeletePendingEnrollment} from "../../../api/PendingEnrollmentApi";
import {List} from "@mui/material";
import {GetClassesAndEnrollments} from "../../../api/ClassApi";
import ClassEnrollmentList from "../../../components/ClassEnrollment/ClassEnrollmentList";

export default function InstructorEnrollmentPage() {
  const [items, setItems] = useState([]);
  const inputs = useInputs(InstructorEnrollmentInputs);
  const {enqueueSnackbar} = useSnackbar();

  const populate = () => {
    GetClassesAndEnrollments().then(json => {
      setItems(json);
    })
  }

  React.useEffect(() => {
    populate()
  }, []);

  const handleDelete = async (e, item) => {
    console.log(item);

    const operation = item.email ? DeletePendingEnrollment : DeleteEnrollment;


    return operation(item.id).then(() => {
      populate()
      enqueueSnackbar('Successfully deleted enrollment', {variant: 'success'});
    });
  }

  const handleEdit = async (values) => {

    return UpdateEnrollment(values.id, values).then(json => {
      setItems(ItemUtils.replaceItem(items, json));
    });
  }

  return (
      <Page title="Enrollments" sx={{ position: 'relative'}}>
        <GenericInputLayout
            header="Enrollments"
            newHeader="Add Enrollment"
            inputs={inputs}
            items={items}
            createAction={CreatePendingEnrollment}
            onCreate={populate}
            onEdit={handleEdit}
            getDisplayName={item => item.name}
            getListContent={(items, openModal) => (
              <List>
                {items.map(clazz => (
                    <ClassEnrollmentList item={clazz} onDelete={handleDelete} openModal={openModal} />
                ))}
              </List>
            )}
        />
      </Page>
  )
}