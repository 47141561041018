import React from 'react';
import Page from "../../components/Page";
import {useState} from "react";
import useInputs from "../../hooks/useInputs";
import GenericInputLayout from "../../layouts/GenericInput/GenericInputLayout";
import ItemUtils from "../../utils/ItemUtils";
import {useSnackbar} from "notistack";
import {CreateInstructor, DeleteInstructor, GetInstructors, UpdateInstructor} from "../../api/InstructorApi";
import {InstructorInputs} from "./InstructorInputs";

export default function InstructorPage() {
  const [items, setItems] = useState([]);
  const inputs = useInputs(InstructorInputs);
  const {enqueueSnackbar} = useSnackbar();

  const populate = () => {
    GetInstructors().then(json => {
      setItems(json);
    })
  }

  React.useEffect(() => {
    populate()
  }, []);

  const handleDelete = async (e, item) => {

    return DeleteInstructor(item.id).then(() => {
      setItems(ItemUtils.removeItem(items, item));
      enqueueSnackbar(`Successfully deleted instructor: ${item.name}`, {variant: 'success'});
    });
  }

  const handleEdit = async (values) => {

    return UpdateInstructor(values.id, values).then(json => {
      setItems(ItemUtils.replaceItem(items, json));
    });
  }

  return (
      <Page title="Instructors" sx={{ position: 'relative'}}>
        <GenericInputLayout
            header="Instructors"
            newHeader="Instructor"
            inputs={inputs}
            items={items}
            filterBy={'name'}
            createAction={CreateInstructor}
            onCreate={populate}
            onEdit={handleEdit}
            onDelete={handleDelete}
            getDisplayName={item => item.name}
        />
      </Page>
  )
}