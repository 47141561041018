import React from "react";
import {PendingAssignmentWidget} from "../../components/widget/PendingAssignments/PendingAssignmentWidget";
import {Box, Grid, Stack} from "@mui/material";
import AppWidgetSummary from "../../components/widget/AppWidgetSummary";
import {GetTermDaysLeft} from "../../api/TermApi";
import AppWidgetHolidays from "../../components/widget/AppWidgetHolidays";
import AppWidgetCalendar from "../../components/widget/AppWidgetCalendar";

export default function InstructorHomePage () {
  const [termDaysLeft, setTermDaysLeft] = React.useState()

  React.useEffect(() => {
    GetTermDaysLeft().then(json => {
      setTermDaysLeft(json);
    })
  }, [])

  return (
      <Stack spacing={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1} direction="column">
              <Grid item xs={12} sm={6}>
                { termDaysLeft?.name && (
                    <AppWidgetSummary title="Days Left In Term"
                                      subtitle={`${termDaysLeft.name}: ${termDaysLeft.daysLeft} days`}
                                      icon="tabler:brand-days-counter" />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <PendingAssignmentWidget />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <AppWidgetHolidays />
          </Grid>
          <Grid item xs={12}>
            <AppWidgetCalendar />
          </Grid>
        </Grid>



      </Stack>
  )
}