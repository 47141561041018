import React, {useState} from 'react';
import PropTypes from "prop-types";
import {getUser} from "../api/UserApi";
import {useCookies} from "react-cookie";
import useFetchConfig from "../api/useFetchConfig";

const functionTemplate = () => {}

const globalContext = {
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
  },
  token: null,
  work: [],
  update: functionTemplate
}

export const GlobalContext = React.createContext(globalContext);

GlobalContext.propTypes = {
  user: PropTypes.exact({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.string
  }),
  work: PropTypes.array,
  token: PropTypes.string,
  update: PropTypes.func
}

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default function GlobalProvider({ children }) {
  const [context, setContext] = React.useState(globalContext);
  const [ready, setReady] = useState(false);
  const [cookies] = useCookies(['token']);
  useFetchConfig(context);

  const updateContext = (contextUpdates = {}) =>
      setContext(currentContext => ({ ...currentContext, ...contextUpdates }));

  React.useEffect(() => {
    if (context?.update === functionTemplate) {
      updateContext({
        update: value => updateContext(value),
      });
      setReady(true);
    }
  }, [context?.update]);

  React.useEffect(() => {
    if (context?.token || cookies.token) {
      (async function fetchData() {
        const user = await getUser();
        updateContext({user})
      })()
    }
  }, [context?.token, cookies.token]);

  return (
      <GlobalContext.Provider value={context}>
        {ready === true ? children : <></>}
      </GlobalContext.Provider>
  )
}