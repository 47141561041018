// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import "react-big-calendar/lib/css/react-big-calendar.css";

// ----------------------------------------------------------------------

export default function App() {
  return (
      <ThemeProvider>
        <Router />
      </ThemeProvider>
  );
}