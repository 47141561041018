import {Card, CardContent, CardHeader, Modal, Slide} from "@mui/material";
import InputForm from "../../components/form/InputForm";
import React from 'react';
import {ErrorHandler} from "../../utils/ErrorHandler";
import {useSnackbar} from "notistack";
import {styled} from "@mui/material/styles";

const StyledCard = styled(Card)({
  margin: '15px auto',
  width: 400,
  bgcolor: 'background.paper',
})

export default function GenericEditModal({ inputs, open, handleModalClose, onSubmit, onChange=()=>{} }) {
  const {enqueueSnackbar} = useSnackbar();

  const handleSubmit = (values) => {
    onSubmit(values).then(() => {
      handleModalClose();
    }).catch(error => ErrorHandler.enqueueError(error, enqueueSnackbar));
  }

  return (
      <Modal open={open} onClose={handleModalClose} sx={{ overflow: 'auto' }}>
        <Slide in={open}>
          <StyledCard>
            <CardHeader title="Edit" />
            <CardContent>
              <InputForm inputs={inputs} onSubmit={handleSubmit} isEdit={true} submitText="Change" handleChange={onChange} />
            </CardContent>
          </StyledCard>
        </Slide>
      </Modal>
  )
}