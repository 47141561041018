import React, {useContext, useState} from 'react';
import {Box, Button, duration, Stack} from "@mui/material";
import {GlobalContext} from "../../../providers/GlobalProvider";
import {UpdateWork} from "../../../api/WorkApi";
import {CreateProgress, UpdateProgress} from "../../../api/ProgressApi";
import AppWorkTimer from "./AppWorkTimer";


export default function AppWorkItemButtons({ item, generateWork }) {
  const { work, update, user } = useContext(GlobalContext);
  const [timerActive, setTimerActive] = useState(false)
  const [time, setTime] = useState(0);

  const handleStartPause = () => {
    if (timerActive || item.progress) {
      if (timerActive) {
        setTimerActive(false);
        UpdateProgress(item.progress.id,{ ...item.progress, duration: time }).then(json => {
          generateWork()
        });
      } else {
        setTimerActive(!timerActive);
      }
    } else {
      CreateProgress({ work: { id: item.id }, owner: { id: user.id }}).then(json => {
        generateWork();
      });
      setTimerActive(true);
    }
  }

  const handleTimeUpdate = (newTime) => {
    setTime(newTime);
  }

  const handleDone = () => {
    UpdateWork(item.id, {...item, completed: !item.completed}).then(json => {
      work.forEach(it => {
        if (it.id === json.id) {
          it.completed = json.completed;
        }
      });

      update({ work });
    });
  }

  return (
      <Stack direction="row"
             spacing={1}
             justifyContent="space-between"
             alignItems="center">
        <Box paddingLeft={1}>
          <AppWorkTimer {...{item, timerActive, handleTimeUpdate, time }} />
        </Box>
        <Stack direction="row" spacing={1}>
          <Button disabled={item.completed}
                  onClick={handleStartPause}
                  sx={{ boxShadow: 0 }}
                  color={timerActive ? 'warning' : 'success' }
                  variant="contained">
            { timerActive ? 'Pause' : 'Start' }
          </Button>
          <Button disabled={timerActive /*|| !item.progress?.duration*/}
                  onClick={handleDone} sx={{ boxShadow: 0 }}
                  color={ item.completed ? "error" : "primary"}
                  variant="contained">
            { item.completed ? 'Incomplete' : 'Complete' }
          </Button>
        </Stack>
      </Stack>
  )
}