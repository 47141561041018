import {IconButton, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import Iconify from "../../components/Iconify";
import React from "react";
import {ErrorHandler} from "../../utils/ErrorHandler";
import {useSnackbar} from "notistack";

export default function GenericListItems({
   items,
   inputs,
   onDelete,
   setOpen,
   onClick,
   getDisplayName,
   customButtons,
   allowEdit= true,
   ...other
}) {
  const {enqueueSnackbar} = useSnackbar();

  const handleItemSelect = (e, item) => {
    if (onClick) {
      onClick(e, item);
    } else if (allowEdit){
      inputs.updateEditInputs(item);

      setOpen(true);
    }
  }

  const handleDelete = (e, item) => {
    e.stopPropagation();

    onDelete(e, item).catch(error => ErrorHandler.enqueueError(error, enqueueSnackbar));
  }

  return (
      <List {...other}>
        <>
          {items.map(item => (
              <ListItemButton key={item.id} sx={{ borderRadius: 1.25 }} disableRipple={!allowEdit} onClick={ (e) => handleItemSelect(e, item) }>
                <ListItemText>{getDisplayName(item)}</ListItemText>
                { customButtons ? customButtons(item) : (
                    <ListItemIcon onClick={(e) => handleDelete(e, item)} sx={{ minWidth: 16 }}>
                        <IconButton size="small">
                          <Iconify icon="ep:close-bold" />
                        </IconButton>
                    </ListItemIcon>
                )}
              </ListItemButton>
          ))}
        </>
      </List>
  );
}