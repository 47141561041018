
export const getUser = async () => {
    return await fetchApi('/users/logged-in');
}

export const createUser = (userRequest) => {
    return fetchApi('/users/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userRequest)
    })
}