import React from "react";
import {Collapse, Divider, IconButton, List, ListItem, ListItemButton, Typography} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import AppWorkItem from "./AppWorkItem";

export default function AppWorkClass({ className, items, generateWork }) {
  const [open, setOpen] = React.useState(false);

  return (
      <>
        <ListItem
            secondaryAction={
              <IconButton onClick={() => setOpen(!open)}>
                { open ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            }
            disablePadding>
          <ListItemButton onClick={() => setOpen(!open)}>
            <Typography variant="subtitle1">{className} ({items.filter(it => !it.completed).length})</Typography>
          </ListItemButton>
        </ListItem>
        <Collapse in={open}>
          <List sx={{ marginLeft: 2 }}>
            { items.map((item, idx) => (
                <React.Fragment key={item.id}>
                  <AppWorkItem item={item} generateWork={generateWork} />
                  {idx + 1 < items.length && (
                      <Divider component="li" />
                  )}
                </React.Fragment>
            ))}
          </List>
        </Collapse>
      </>
  )
}