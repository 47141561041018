import FetchWrapper from "./FetchWrapper";

export const GetCourses = () => {
  return fetchApi("/courses")
}

export const CreateCourse = (payload) => {
  return fetchApi('/courses', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
}

export const UpdateCourse = (id, payload) => {
  return fetchApi(`/courses/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
}

export const DeleteCourse = (id) => {
  return fetchApi(`/courses/${id}`, { method: 'DELETE' })
}