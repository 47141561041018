// component
import Iconify from '../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const teacherAuth = (type) => {
  return type === 'TEACHER';
}

const navConfig = [
  {
    title: 'home',
    path: '/home',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'terms',
    path: '/terms',
    icon: getIcon('ant-design:calendar-outlined'),
    auth: teacherAuth
  },
  {
    title: 'subjects',
    path: '/subjects',
    icon: getIcon('bx:globe-alt'),
    auth: teacherAuth
  },
  {
    title: 'material types',
    path: '/material-types',
    icon: getIcon('carbon:tool-box'),
    auth: teacherAuth
  },
  {
    title: 'materials',
    path: '/materials',
    icon: getIcon('el:book'),
    auth: teacherAuth
  },
  {
    title: 'courses',
    path: '/courses',
    icon: getIcon('clarity:list-solid'),
    auth: teacherAuth
  },
  {
    title: 'classes',
    path: '/classes',
    icon: getIcon('clarity:list-solid'),
    auth: teacherAuth
  },
  {
    title: 'enrollments',
    path: '/enrollments',
    icon: getIcon('ic:baseline-people'),
  },
  {
    title: 'assignment types',
    path: '/assignment-types',
    icon: getIcon('ic:baseline-assignment'),
    auth: teacherAuth
  },
  {
    title: 'assignments',
    icon: getIcon('ic:baseline-assignment'),
    auth: teacherAuth,
    children: [
      {
        title: 'Sequence',
        path: '/assignments/sequences',
        icon: getIcon('ic:baseline-assignment'),
      },
      {
        title: 'Routine',
        path: '/assignments/routines',
        icon: getIcon('ic:baseline-assignment'),
      },
      {
        title: 'Date',
        path: '/assignments/dates',
        icon: getIcon('ic:baseline-assignment'),
      }
    ]
  },
  {
    title: 'day of week',
    path: '/day-of-week',
    icon: getIcon('bi:calendar-day-fill'),
    auth: teacherAuth
  },
  {
    title: 'instructors',
    path: '/instructors',
    icon: getIcon('la:chalkboard-teacher'),
    auth: teacherAuth
  },
  {
    title: 'facilities',
    path: '/facilities',
    icon: getIcon('bxs:business'),
    auth: teacherAuth
  },
  {
    title: 'holidays',
    path: '/holidays',
    icon: getIcon('fluent-emoji-high-contrast:christmas-tree'),
    auth: teacherAuth
  },
];

export default navConfig;