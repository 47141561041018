
export const GetProgress = () => {
  return fetchApi("/progress")
}

export const CreateProgress = (subject) => {
  return fetchApi('/progress', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subject)
  });
}

export const UpdateProgress = (id, subject) => {
  return fetchApi(`/progress/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subject)
  });
}

export const DeleteProgress = (id) => {
  return fetchApi(`/progress/${id}`, { method: 'DELETE' })
}