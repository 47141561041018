import React from 'react';
import {Controller} from "react-hook-form";
import RHFComponent from "./RHFComponent";

export default function RHFController({ input, control, isEdit }) {


  return (
      <Controller
          name={input.name}
          rules={input.validation}
          defaultValue={input.value ? input.value : input.defaultValue}
          render={(rhf) =>
              <RHFComponent {...{input, rhf, isEdit}} />
          }
      />
  )
}