import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import RHFController from "./RHFController";
import React from "react";

export default function RHFChoice({ input, field, fieldWithoutRef, error, disabled}) {

  /*input.options.filter(it => it.value !== field.value).forEach(it => {

    it.children.forEach(child => {
      console.log(control._fields, child.name);
      if (control._fields[child.name]) {
        control.unregister(child.name, {keepValue: false});
      }
    })
  });*/

  return (
      <>
        <FormControl fullWidth disabled={disabled}>
          <InputLabel id={input.name}>{input.label}</InputLabel>
          <Select labelId={input.name} label={input.label} {...fieldWithoutRef} inputRef={field.ref} error={!!error}>
            {input.options.map(option => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        { input.options.filter(option => option.value === field.value).map(option => {

          return option.children?.map(child => (
              <RHFController key={child.name} input={child} />
          ));
        })}
      </>
  )
}