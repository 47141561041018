import {useContext, useRef, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from '../components/MenuPopover';
// mocks_
import account from '../_mock/account';
import useLogoutActions from "../hooks/useLogoutActions";
import {GlobalContext} from "../providers/GlobalProvider";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Home',
        icon: 'eva:home-fill',
        linkTo: '/',
    },
    {
        label: 'Profile',
        icon: 'eva:person-fill',
        linkTo: '#',
    },
    {
        label: 'Settings',
        icon: 'eva:settings-2-fill',
        linkTo: '#',
    },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const { logout } = useLogoutActions();
  const context = useContext(GlobalContext);
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
      setOpen(event.currentTarget);
  };

  const handleClose = () => {
      setOpen(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  }

  return (
      <>
          <IconButton
              ref={anchorRef}
              onClick={handleOpen}
              sx={{
                  p: 0,
                  ...(open && {
                      '&:before': {
                          zIndex: 1,
                          content: "''",
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          position: 'absolute',
                          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                      },
                  }),
              }}
          >
            <Avatar>{context.user.firstName.charAt(0)}</Avatar>
          </IconButton>

          <MenuPopover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleClose}
              sx={{
                  p: 0,
                  mt: 1.5,
                  ml: 0.75,
                  '& .MuiMenuItem-root': {
                      typography: 'body2',
                      borderRadius: 0.75,
                  },
              }}
          >
              <Box sx={{ my: 1.5, px: 2.5 }}>
                <Typography variant="subtitle2" noWrap>
                  {context.user.firstName} {context.user.lastName}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                  {context.user.email}
                </Typography>
              </Box>

              <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                Logout
              </MenuItem>
          </MenuPopover>
      </>
  );
}