import * as Yup from 'yup';
import {useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../components/Iconify';
import RHFTextField from "./form-component/RHFTextField";
import FormProvider from "../../providers/FormProvider";
import RHFChoice from "./form-component/RHFChoice";
import RHFController from "./form-component/RHFController";
import {createUser} from "../../api/UserApi";
import {useSnackbar} from "notistack";
import {ErrorHandler} from "../../utils/ErrorHandler";
import {handleLogin} from "../../actions/LoginActions";
import {GlobalContext} from "../../providers/GlobalProvider";

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const context = useContext(GlobalContext);

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    type: Yup.string().required('Please select a type'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    type: '',
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
      control,

  } = methods;

  const onSubmit = async (user) => {
    createUser(user).then(json => {
      handleLogin(context, json)
      navigate('/', { replace: true })
    }).catch(error => {
      ErrorHandler.enqueueError(error, enqueueSnackbar);
    })
  };

  const typeInput = { name: 'type', label: "User Type", type: 'select', defaultValue: '', options: [
      { label: "Student", value: 'STUDENT' },
      { label: "Teacher", value: 'TEACHER' }
    ]
  }

  return (
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <RHFTextField name="firstName" label="First name" />
            <RHFTextField name="lastName" label="Last name" />
          </Stack>

          <RHFController control={control} input={typeInput} />

          <RHFTextField name="email" label="Email address" />

          <RHFTextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                ),
              }}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Register
          </LoadingButton>
        </Stack>
      </FormProvider>
  );
}