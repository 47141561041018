import AssignmentSequencePage from "./Sequence/AssignmentSequencePage";
import AssignmentDatePage from "./Date/AssignmentDatePage";
import AssignmentRoutinePage from "./Routine/AssignmentRoutinePage";
import {Box, Button, Card, CardContent, CardHeader, IconButton, Stack, TextField, Typography} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, {useState} from "react";
import Page from "../../components/Page";
import {GetClasses} from "../../api/ClassApi";
import {SearchContext} from "../../providers/SearchProvider";
import {Delete, DeleteSweep} from "@mui/icons-material";
import {DeleteAllSequenceAssignments} from "../../api/SequenceAssignmentApi";
import {ErrorHandler} from "../../utils/ErrorHandler";
import {useSnackbar} from "notistack";

export default function AssignmentPage({ type }) {
  const {enqueueSnackbar} = useSnackbar();
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState({});
  const [search, setSearch] = React.useState('');
  const [reload, setReload] = useState(false);

  React.useEffect(() => {
    GetClasses().then(json => {
      setClasses(json);
      if (json.length > 0) {
        setSelectedClass(json[0]);
      }
    })
  }, []);

  const handleClassChange = (e, value) => {
    setSelectedClass(value);
    setSearch('')
  }

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  const handleDeleteAll = () => {
    if (window.confirm("Are you sure you want to delete all assignments?")) {
      // Delete all assignments
      DeleteAllSequenceAssignments(selectedClass.id).then(() => {
        enqueueSnackbar('All sequences deleted', { variant: 'success' });
        setReload(true);
      }).catch(error => ErrorHandler.enqueueError(error, enqueueSnackbar));
    }
  }

  return (
      <Box>
        <Page title="Assignments" sx={{ position: 'relative'}}>
          <SearchContext.Provider value={search}>
            <Stack spacing={1}>
              <Card>
                <CardHeader title="Class" />
                <CardContent>
                  {classes.length > 0 ? (
                      <Stack spacing={1} alignItems="end">
                        <Autocomplete
                            fullWidth
                            value={selectedClass}
                            onChange={handleClassChange}
                            blurOnSelect
                            options={classes}
                            getOptionLabel={(option) => option.name || ''}
                            renderInput={(params) => <TextField {...params} label="Select a class" />}
                        />
                        <TextField fullWidth label="Search Assignment" value={search} onChange={handleSearch} />
                        {type === "SEQUENCE" && (
                            <Button sx={{ width: 120 }} onClick={handleDeleteAll} startIcon={<DeleteSweep />}>Delete All</Button>
                        )}
                      </Stack>
                  ) : (
                      <Typography>Please add some classes before creating assignments</Typography>
                  )}

                </CardContent>
              </Card>
              <>
                {type === 'SEQUENCE' && <AssignmentSequencePage {...{selectedClass, reload, setReload}} />}
                {type === 'DATE' && <AssignmentDatePage {...{selectedClass}} />}
                {type === 'ROUTINE' && <AssignmentRoutinePage {...{selectedClass}} />}
              </>
            </Stack>
          </SearchContext.Provider>
        </Page>
      </Box>
  )
}