import React from 'react';
import Page from "../../components/Page";
import {useState} from "react";
import useInputs from "../../hooks/useInputs";
import GenericInputLayout from "../../layouts/GenericInput/GenericInputLayout";
import ItemUtils from "../../utils/ItemUtils";
import {useSnackbar} from "notistack";
import {HolidayInputs} from "./HolidayInputs";
import {CreateHoliday, DeleteHoliday, GenerateHolidays, GetHolidays, UpdateHoliday} from "../../api/HolidayApi";
import moment from "moment";
import {Box, Button, Stack, Typography} from "@mui/material";
import {ErrorHandler} from "../../utils/ErrorHandler";

export default function HolidayPage() {
  const [items, setItems] = useState([]);
  const inputs = useInputs(HolidayInputs);
  const {enqueueSnackbar} = useSnackbar();

  const populate = () => {
    GetHolidays().then(json => {
      json.forEach(item => {
        item.observedOn = moment(item.observedOn)
        item.date = moment(item.date)
      })
      setItems(json);
    })
  }

  React.useEffect(() => {
    populate()
  }, []);

  const handleDelete = async (e, item) => {

    return DeleteHoliday(item.id).then(() => {
      setItems(ItemUtils.removeItem(items, item));
      enqueueSnackbar(`Successfully deleted holiday: ${item.name}`, {variant: 'success'});
    });
  }

  const handleEdit = async (values) => {

    return UpdateHoliday(values.id, values).then(json => {
      setItems(ItemUtils.replaceItem(items, json));
    });
  }

  const handleChange = (name, value, setValue) => {
    if ((name === 'sameAsDate' || name === 'date') && value.sameAsDate) {
      setValue('observedOn', value.date);
      inputs.updateInput('observedOn', (input) => input.disabled = true)
    } else if (name === 'sameAsDate') {
      inputs.updateInput('observedOn', (input) => input.disabled = false)
    }
  }

  const handleGenerateHolidays = () => {
    GenerateHolidays().then(() => {
      populate();
    }).catch(error => ErrorHandler.enqueueError(error, enqueueSnackbar));
  }

  return (
      <Page title="Holidays" sx={{ position: 'relative'}}>
        <GenericInputLayout
            header="Holiday"
            headerAction={<Button variant="outlined" onClick={handleGenerateHolidays}>Generate Holidays</Button>}
            newHeader="Add Holiday"
            inputs={inputs}
            items={items}
            filterBy={'name'}
            createAction={CreateHoliday}
            onCreate={populate}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onChange={handleChange}
            getDisplayName={item => (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>{item.name}</Typography>
                  <Typography variant="caption">({item.observedOn.format('MM/DD/YYYY')})</Typography>
                </Stack>)}
        />
      </Page>
  )
}