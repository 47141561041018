import React from "react";

const functionTemplate = () => {}
const searchContext = {
  search: '',
  update: functionTemplate
}
export const SearchContext = React.createContext('');


export default function SearchProvider({ children }) {
  /*const [context, setContext] = React.useState(searchContext);

  const updateContext = (contextUpdates = {}) =>
      setContext(currentContext => ({ ...currentContext, ...contextUpdates }));

  React.useEffect(() => {
    if (context?.update === functionTemplate) {
      updateContext({
        update: value => updateContext(value),
      });
    }
  }, [context?.update]);

  return (
      <SearchContext.Provider value={context}>
        { children }
      </SearchContext.Provider>
  )*/

  return <></>
}