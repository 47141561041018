import React from 'react';
import {useState} from "react";
import {CreateSubject, DeleteSubject, GetSubjects, UpdateSubject} from "../../api/SubjectApi";
import GenericInputLayout from "../../layouts/GenericInput/GenericInputLayout";
import ItemUtils from '../../utils/ItemUtils';
import Page from "../../components/Page";
import useInputs from "../../hooks/useInputs";
import {useSnackbar} from "notistack";
import {SubjectInputs} from "./SubjectInputs";


export default function SubjectPage() {
  const [items, setItems] = useState([]);
  const inputs = useInputs(SubjectInputs);
  const {enqueueSnackbar} = useSnackbar();

  const populate = () => {
    GetSubjects().then(json => {
      setItems(json);
    });
  }

  React.useEffect(() => {
    populate();
  }, [])

  const handleDelete = (e, item) => {
    return DeleteSubject(item.id).then(() => {
      setItems(ItemUtils.removeItem(items, item));
      enqueueSnackbar(`Successfully deleted subject: ${item.name}`, {variant: 'success'});
    })
  }

  const handleEdit = (values) => {
    return UpdateSubject(values.id, { name: values.name }).then(json => {
      setItems(ItemUtils.replaceItem(items, json));
    })
  }

  return (
      <Page title="Subjects" sx={{ position: 'relative'}}>
        <GenericInputLayout
            header="Subjects"
            newHeader="Add Subject"
            inputs={inputs}
            items={items}
            filterBy={'name'}
            createAction={CreateSubject}
            onCreate={populate}
            onDelete={handleDelete}
            onEdit={handleEdit}
            getDisplayName={item => item.name}
        />
      </Page>
  )
}