import React from "react";
import moment from "moment/moment";
import {Calendar, momentLocalizer, Views} from "react-big-calendar";
import {Box} from "@mui/material";

const mLocalizer = momentLocalizer(moment);

export default function CalendarComponent({ startDate, events }) {


  return (
      <Box sx={{
        '& .rbc-time-header': { height: '100%' },
        '& .rbc-time-header-gutter': { display: 'none'},
        '& .rbc-time-content': { display: 'none' }
      }}>
        <Calendar
            defaultDate={startDate}
            localizer={mLocalizer}
            defaultView={Views.WEEK}
            views={[Views.WEEK, Views.AGENDA]}
            events={events}
        />
      </Box>

  )
}