import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid, Stack, TextField,
} from "@mui/material";
import React, {useState} from "react";
import GenericAddItem from "./GenericAddItem";
import GenericEditModal from "./GenericEditModal";
import GenericListItems from "./GenericListItems";

export default function GenericInputLayout({
  header,
  headerAction,
  newHeader,
  inputs,
  items,
  createAction,
  onClick,
  onCreate,
  onEdit,
  onDelete,
  getDisplayName,
  filterBy,
  getListContent,
  onChange=()=>{}
}) {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = useState('')
  const [filtered, setFiltered] = React.useState([])

  React.useEffect(() => {
    if (filterBy && search.trim().length > 0) {
      setFiltered(items.filter(item =>
          item[filterBy].toUpperCase().includes(search.toUpperCase())))
    } else {
      setFiltered(items)
    }
  }, [items, search, filterBy])

  const handleModalClose = () => {
    setOpen(false);
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  }

  return (
      <>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <GenericAddItem inputs={inputs.inputs}
                            header={newHeader}
                            createAction={createAction}
                            onCreate={onCreate}
                            onChange={onChange} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Card>
              <CardHeader title={header} action={headerAction} />
              <CardContent sx={{ pt: 1.5 }}>
                <Stack>
                  {filterBy && <TextField label="Search" value={search} onChange={handleSearchChange} /> }
                  {getListContent != null
                      ? getListContent(items, setOpen)
                      : <GenericListItems onClick={onClick} items={filtered} {...{ inputs, onDelete, setOpen, getDisplayName }} />}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <GenericEditModal inputs={inputs.editInputs} onChange={onChange} onSubmit={onEdit} {...{open, handleModalClose}} />
      </>
  );
}