import FetchWrapper from "./FetchWrapper";

export const GetMaterials = () => {
  return fetchApi("/materials")
}

export const CreateMaterial = (subject) => {
  return fetchApi('/materials', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subject)
  });
}

export const UpdateMaterial = (id, subject) => {
  return fetchApi(`/materials/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subject)
  });
}

export const DeleteMaterial = (id) => {
  return fetchApi(`/materials/${id}`, { method: 'DELETE' })
}