import React from 'react'
import {SearchContext} from "../providers/SearchProvider";
import * as AssignmentUtils from "../utils/AssignmentUtils";

export default function useFilteredGroups() {
  const search = React.useContext(SearchContext);
  const [items, setItems] = React.useState([]);
  const [filtered, setFiltered] = React.useState([]);

  React.useEffect(() => {
    if (search?.trim().length > 0) {
      setFiltered(AssignmentUtils.filterGroupedAssignments(items, search));
    } else {
      setFiltered(items)
    }
  }, [items, search, setFiltered])

  return {
    items,
    setItems,
    filtered,
    setFiltered
  }
}