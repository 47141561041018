import ArrayUtils from "./ArrayUtils";

export const orderAssignments = (assignments) => {

  for (let i = 0; i < assignments.length; i++) {
    const assignment = assignments[i];
    if (assignment.after && (i === 0 || assignment.after !== assignments[i - 1].id)) {
      for (let j = 0; j < assignments.length; j++) {
        if (assignments[j].id === assignment.after) {
          assignments = ArrayUtils.move(assignments, i, j);
          i = -1;
          break;
        }
      }
    }
  }

  return [...assignments];
}

export const updateOrdering = (assignments, source, destination) => {
  const updateItems = [];

  const src = source.index;
  const dest = destination.index;
  const length = assignments.length;

  if (src !== dest) {
    if (src === 0) {
      assignments[src + 1].after = null;
      updateItems.push(assignments[src + 1]);
    } else if (src < length - 1) {
      assignments[src + 1].after = assignments[src - 1].id;
      updateItems.push(assignments[src + 1]);
    }

    if (src < dest) {
      assignments[src].after = assignments[dest].id;
    } else if (dest === 0) {
      assignments[src].after = null;
    } else {
      assignments[src].after = assignments[dest - 1].id;
    }
    updateItems.push(assignments[src]);

    if (dest < length - 1) {
      if (src < dest) {
        assignments[dest + 1].after = assignments[src].id;
        updateItems.push(assignments[dest + 1]);
      } else {
        assignments[dest].after = assignments[src].id;
        updateItems.push(assignments[dest]);
      }
    }
  }

  assignments = ArrayUtils.move(assignments, source.index, destination.index);

  return {
    assignments,
    updateItems
  }
}

export const updateGroupOrdering = (items, assignments, parent) => {
  const [classes] = assignments.map(it => it.classes);

  items.forEach(it => {
    if (it.id === classes.id) {
      it.groups.forEach(group => {
        if (group.name === parent.name) {
          group.assignments = assignments;
        }
      })
    }
  });

  return items;
}

export const getByOccurrenceType = (item, type) => {
  return item.assignments
      .filter(assign => assign.occurrenceType === type);
}

export const filterGroupedAssignments = (items, search) => {
  return items.map(item => {
    const groups = item.groups.map(group => {
      const assignments = group.assignments.filter(assignment =>
          assignment.name.toUpperCase().includes(search.toUpperCase()));

      return { ...group, assignments }
    }).filter(group => group.assignments.length > 0);

    return { ...item, groups };
  });
}

export const splitAssignments = (item) => {
  return {
    template: getByOccurrenceType(item, 'template'),
    date: getByOccurrenceType(item, 'date'),
  }
}