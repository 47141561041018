import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {TextField} from "@mui/material";
import _ from 'underscore';


export default function AutocompleteTextbox({ input, field, error }) {
  const [options, setOptions] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const fieldWithoutRef = {...field};
  delete fieldWithoutRef.ref;

  React.useEffect(() => {
    (_.debounce(() => {
      (input.getItemsParams ? input.getItems(...input.getItemsParams) : input.getItems()).then(json => {
        setOptions(json);

        if (input.value) {
          const selected = json.find(it => it[input.optionValue] === input.value);
          if (selected) {
            field.value = selected;
          }
        }
      });
    }, 500))()
  }, [input]);

  function handleChange(e, value) {
    field.onChange(value);
  }

  const additionalFields = {}
  if (input.multiple) {
    additionalFields['multiple'] = true;
  }

  return (
      <>
        <Autocomplete
            {...fieldWithoutRef}
            {...additionalFields}
            options={options}
            getOptionLabel={(option) => option[input.optionValue] }
            isOptionEqualToValue={(option, value) =>
                option[input.optionValue] === value[input.optionValue] }
            open={open}
            clearOnBlur={false}
            onChange={handleChange}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            renderInput={
              (params) => {
                 return <TextField
                    {...params}
                    label={input.label}
                    error={!!error}
                    inputRef={field.ref}
                    helperText={error?.message}
                />
              }
            }
        />
      </>
  )
}