import FetchWrapper from "./FetchWrapper";

export const GetWork = () => {
  return fetchApi("/work")
}

export const GetPendingWork = () => {
  return fetchApi(`/work/pending`)
};

export const GenerateWork = (ids) => {
  if (ids) {
    ids = ids.map(id => `enrollmentIds=${id}`)
  } else {
    ids = []
  }

  return fetchApi(`/work/generate?${ids.join('&')}`)
}

export const CreateWork = (work) => {
  return fetchApi('/work', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(work)
  });
}

export const UpdateWork = (id, work) => {
  return fetchApi(`/work/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(work)
  });
}

export const ReviewWork = (id, work) => {
  return fetchApi(`/work/${id}/review`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(work)
  });
}

export const DeleteWork = (id) => {
  return fetchApi(`/work/${id}`, { method: 'DELETE' })
}