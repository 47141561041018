import React, {useState} from "react";
import {Collapse, IconButton, ListItemButton, ListItemText, Stack, Typography} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import GenericListItems from "../../layouts/GenericInput/GenericListItems";
import { useNavigate } from "react-router-dom";

export default function ClassEnrollmentList({ item, onDelete, customButtons }) {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const length = item.pending.length + item.enrollments.length;

  const handleClick = (e, item) => {
    console.log(item);
    navigate(`/enrollments/${item.id}`);
  }

  return (
      <>
        <ListItemButton sx={{ borderRadius: 1.5 }} onClick={() => setOpen(!open)}>
          <ListItemText>{item.classes.name} { length > 0 && `(${length})`}</ListItemText>
          <IconButton size="small">
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemButton>
        <Collapse in={open}>
          <>
            {item.pending.length > 0 && (
                <Stack ml={3}>
                  <Typography variant="h6">
                    Pending Enrollments
                  </Typography>
                  <GenericListItems items={item.pending}
                                    onDelete={onDelete}
                                    allowEdit={false}
                                    customButtons={customButtons}
                                    getDisplayName={(it) => it.email} />
                </Stack>
            )}
            {item.enrollments.length > 0 && (
                <Stack ml={3}>
                  <Typography variant="h6">
                    Active Enrollments
                  </Typography>
                  <GenericListItems items={item.enrollments}
                                    onDelete={onDelete}
                                    onClick={handleClick}
                                    getDisplayName={(it) => it.student.firstName} />
                </Stack>
            )}
          </>
        </Collapse>
      </>
  )
}