import {Box, CircularProgress} from "@mui/material";
import React from "react";

const LoadingSpinner = () => {
  return (
      <Box sx={{ display: 'flex', mt: 3 }}>
        <CircularProgress />
      </Box>
  )
}

export default LoadingSpinner;