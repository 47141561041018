import {Box, Card, CardContent, CardHeader, IconButton, MenuItem, Select, Stack} from "@mui/material";
import React from "react";
import {GetActiveAndFutureEnrolledStudents, GetEnrollmentEventsForStudent} from "../../api/EnrollmentApi";
import {ErrorHandler} from "../../utils/ErrorHandler";
import CalendarComponent from "../Calendar/CalendarComponent";
import moment from "moment";
import LoadingSpinner from "../LoadingSpinner";
import {OpenInNew} from "@mui/icons-material";


const AppWidgetCalendar = ({  }) => {
  const [items, setItems] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [events, setEvents] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    GetActiveAndFutureEnrolledStudents().then(json => {
      setItems(json);
    }).catch(error => ErrorHandler.enqueueError(error));
  }, []);

  React.useEffect(() => {
    if (items.length > 0) {
      setSelectedItem(items[0].id);
    }
  }, [items]);

  React.useEffect(() => {
    if (selectedItem && !loading) {
      setLoading(true);
      GetEnrollmentEventsForStudent(selectedItem).then(json => {
        setEvents(json.flatMap(it => it.events.map(event => {
          event.title = event.name
          event.start = moment(event.start)
          event.end = moment(event.end)

          return event;
        })));
      })
      .catch(error => ErrorHandler.enqueueError(error))
      .finally(() => setLoading(false));
    }
  }, [selectedItem, setLoading]);

  const handleStudentChange = (event) => {
    setSelectedItem(event.target.value);
  }

  const handleOpen = () => {
    window.open(`/enrollments/students/${selectedItem}/events`, '_blank')
  }

  return  (
      <Card>
        <CardHeader title={"Calendar"} action={
          <IconButton onClick={handleOpen}>
            <OpenInNew />
          </IconButton>} />
        <CardContent sx={{ pt: 1 }}>
          <Stack spacing={1}>
            <Select value={selectedItem} onChange={handleStudentChange} size="small">
              {items.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.fullName}
                  </MenuItem>
              ))}
            </Select>
            { !loading && events.length > 0 ? (
                <CalendarComponent startDate={moment()} events={events} />
            ) : (
                <LoadingSpinner />
            )}
          </Stack>
        </CardContent>
      </Card>
  )
}

export default AppWidgetCalendar;