import React from 'react';
import {Box, Card, CardContent, CardHeader, Grid, List, Typography} from "@mui/material";
import {ErrorHandler} from "../../../utils/ErrorHandler";
import {useSnackbar} from "notistack";
import {PendingUserClassItem} from "./PendingUserClassItem";
import {GetPendingWork} from "../../../api/WorkApi";

export function PendingAssignmentWidget({  }) {
  const {enqueueSnackbar} = useSnackbar();
  const [items, setItems] = React.useState([]);

  const getItems = () => {
    GetPendingWork().then(json => {
      setItems(json);
    }).catch(error => ErrorHandler.enqueueError(error, enqueueSnackbar));
  }


  React.useEffect(() => {
    getItems();
  }, [setItems, enqueueSnackbar]);

  return (
      <Card>
        <CardHeader title={"Work to Review"} />
        <CardContent sx={{ paddingTop: 0 }}>
          { items.length > 0 ? (
              <List sx={{ width: '100%' }}>
                {items.map(item => (
                    <PendingUserClassItem key={item.name} {...{item, getItems}} />
                ))}
              </List>
          ) : (
            <Typography variant="body2" paddingTop={2}>
              No work to review
            </Typography>
          )}

        </CardContent>
      </Card>
  )
}