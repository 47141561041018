import FetchWrapper from "./FetchWrapper";

export const GetDaysOfWeek = () => {
  return fetchApi("/day-of-week")
}

export const CreateDayOfWeek = (subject) => {
  return fetchApi('/day-of-week', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subject)
  });
}

export const UpdateDayOfWeek = (id, subject) => {
  return fetchApi(`/day-of-week/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subject)
  });
}

export const DeleteDayOfWeek = (id) => {
  return fetchApi(`/day-of-week/${id}`, { method: 'DELETE' })
}