import React, {useEffect, useMemo, useState} from "react";
import moment from 'moment'
import {Box, CircularProgress, Stack, Typography} from "@mui/material";
import {GetEnrollmentEventsForStudent} from "../../../api/EnrollmentApi";
import {useParams} from "react-router-dom";
import CalendarComponent from "../../../components/Calendar/CalendarComponent";

export default function StudentClassesCalendarPage() {
  const { id } = useParams();
  const [items, setItems] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    GetEnrollmentEventsForStudent(id).then(json => {
      setItems(json);
      json.forEach(item => {
        setEvents(prevState => [
            ...prevState, ...item.events.map(it => ({ ...it, title: `${it.name} (${it.classes.name})`, start: moment(it.start), end: moment(it.end) }))]);
      });
    })
  }, [id]);

  const defaultDate = useMemo(() => {
    let date = moment();
    if (items.length > 0) {
      date = items.reduce((prev, item) => {
        if (item.classes?.startDate) {
          const start = moment(item.classes?.startDate);
          return prev.isSameOrBefore(start) ? prev : start;
        }

        return prev;
      }, moment());
    }

    return date.isSameOrBefore(moment()) ? moment() : date;
  }, [items]);

  return (
      <Stack spacing={1} alignItems="center">
        <Typography variant="h6">All Classes</Typography>
        { defaultDate && items.length > 0 ? (
            <>
              <Box width="100%">
                <CalendarComponent events={events} startDate={defaultDate} />
              </Box>
            </>
        ) : (
            <Box sx={{ display: 'flex', mt: 3 }}>
              <CircularProgress />
            </Box>
        )}
      </Stack>
  )
}