import FetchWrapper from "./FetchWrapper";

export const GetFacilities = () => {
  return fetchApi("/facilities")
}

export const CreateFacility = (subject) => {
  return fetchApi('/facilities', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subject)
  });
}

export const UpdateFacility = (id, subject) => {
  return fetchApi(`/facilities/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subject)
  });
}

export const DeleteFacility = (id) => {
  return fetchApi(`/facilities/${id}`, { method: 'DELETE' })
}