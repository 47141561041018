import React from 'react';
import Page from "../../components/Page";
import {useState} from "react";
import useInputs from "../../hooks/useInputs";
import GenericInputLayout from "../../layouts/GenericInput/GenericInputLayout";
import ItemUtils from "../../utils/ItemUtils";
import {useSnackbar} from "notistack";
import {
  CreateAssignmentType,
  DeleteAssignmentType,
  GetAssignmentTypes,
  UpdateAssignmentType
} from "../../api/AssignmentTypeApi";
import {AssignmentTypeInputs} from "./AssignmentTypeInputs";

export default function AssignmentTypePage() {
  const [items, setItems] = useState([]);
  const inputs = useInputs(AssignmentTypeInputs);
  const {enqueueSnackbar} = useSnackbar();

  const populate = () => {
    GetAssignmentTypes().then(json => {
      setItems(json);
    })
  }

  React.useEffect(() => {
    populate()
  }, []);

  const handleDelete = async (e, item) => {

    return DeleteAssignmentType(item.id).then(() => {
      setItems(ItemUtils.removeItem(items, item));
      enqueueSnackbar(`Successfully deleted material type: ${item.name}`, {variant: 'success'});
    });
  }

  const handleEdit = async (values) => {

    return UpdateAssignmentType(values.id, values).then(json => {
      setItems(ItemUtils.replaceItem(items, json));
    });
  }

  return (
      <Page title="Assignment Types" sx={{ position: 'relative'}}>
        <GenericInputLayout
            header="Assignment Types"
            newHeader="Add Assignment Type"
            inputs={inputs}
            items={items}
            filterBy={'name'}
            createAction={CreateAssignmentType}
            onCreate={populate}
            onEdit={handleEdit}
            onDelete={handleDelete}
            getDisplayName={item => item.name}
        />
      </Page>
  )
}