import {GetAssignmentTypes} from "../../../api/AssignmentTypeApi";

export const AssignmentDateInputs = [
  { name: 'name', label: "Name", type: 'text', defaultValue: '', validation: { required: "Please enter a name" } },
  { name: 'instructions', label: "Instructions", type: 'text', defaultValue: '', validation: { required: "Please enter some instructions" } },
  { name: 'timeToComplete', label: "Time to Complete (minutes)", type: 'number', defaultValue: 60 },
  {
    name: 'assignmentType',
    label: 'Assignment Type',
    getItems: GetAssignmentTypes,
    optionValue: 'name',
    validation: {
      required: true,
      validate: val => val.name ? true : 'Please choose a type' },
    type: 'autocomplete',
    defaultValue: { name: '' }
  },
  { name: 'date', label: "Date", type: 'date', defaultValue: '', validation: { required: "Please enter a date" } },
  { name: 'preventOtherWork', label: "Prevent Other Work", type: 'checkbox', defaultValue: false },
  {
    name: 'occurrenceType',
    type: 'hidden',
    defaultValue: 'date'
  }
];