import React, {useContext} from "react";
import Page from "../../components/Page";
import {Container, Grid, Stack, Typography} from "@mui/material";
import AppWidgetSummary from "../../components/widget/AppWidgetSummary";
import {GenerateWork} from "../../api/WorkApi";
import AppWork from "../../components/widget/AppWork/AppWork";
import {GlobalContext} from "../../providers/GlobalProvider";

export default function StudentHomePage ({  }) {
  const verse = "For all have sinned and fall short of the glory of God Romans 3:23"
  const { work, update } = useContext(GlobalContext);
  const completedWork = work.filter(it => it.completed).length;
  const incompleteWork = work.filter(it => !it.completed).length;

  React.useEffect(() => {
    generateWork();
  }, []);

  const generateWork = () => {
    return GenerateWork().then(json => {
      update({ work: json.work });
    })
  }

  return (
      <Page title="Home">
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 3 }}>
            Today's Outlook
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary title="Verse of the Week" subtitle={verse} color="primary" icon={'fa6-solid:book-bible'} sx={{ height: 1 }} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary title={completedWork} subtitle="Complete Items" color="success" icon={'carbon:checkmark-filled'} sx={{ height: 1 }} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary title={incompleteWork} subtitle="Incomplete Items" color="warning" icon={'ic:baseline-incomplete-circle'} sx={{ height: 1 }} />
            </Grid>
            {/*<Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary title="0" subtitle="Incorrect Items" color="secondary" icon={'akar-icons:circle-x-fill'} sx={{ height: 1 }} />
            </Grid>*/}
          </Grid>
          { work.length > 0 && (
              <Stack marginTop={3}>
                <Typography variant="h4" sx={{ mb: 3 }}>
                  My Work
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={6}>
                    <AppWork work={work} generateWork={generateWork} />
                  </Grid>
                </Grid>

              </Stack>
          )}
        </Container>
      </Page>
  )
}