
export const GetClasses = () => {
  return fetchApi("/classes")
}

export const GetClassesAndEnrollments = (userType = "TEACHER") => {
  return fetchApi(`/classes/enrollments?userType=${userType}`);
}

export const CreateClass = (payload) => {
  return fetchApi('/classes', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
}

export const UpdateClass = (id, payload) => {
  return fetchApi(`/classes/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
}

export const DeleteClass = (id) => {
  return fetchApi(`/classes/${id}`, { method: 'DELETE' })
}