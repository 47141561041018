import React, {useState} from 'react';
import {Box, Button, Card, CardContent, CardHeader, Modal, Slide, Stack, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import InputForm from "../../form/InputForm";
import useInputs from "../../../hooks/useInputs";
import {PendingWorkInput} from "./Inputs/PendingWorkInput";
import {ReviewWork} from "../../../api/WorkApi";
import {useSnackbar} from "notistack";
import {ErrorHandler} from "../../../utils/ErrorHandler";

const ButtonNoElevation = styled(Button)({
  boxShadow: 'none !important'
});

const StyledCard = styled(Card)({
  margin: '15px auto',
  width: 400,
  bgcolor: 'background.paper',
});


export function PendingWorkItem({ item, odd, getItems}) {
  const {enqueueSnackbar} = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const inputs = useInputs(PendingWorkInput);
  const [grade, setGrade] = React.useState('');
  const [approved, setApproved] = React.useState(false);

  const handleReview = () => {
    setOpen(true);
    inputs.updateEditInputs(item);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSubmit = (data) => {
    ReviewWork(data.id, { approved, ...data}).then(() => {
      setOpen(false);
      getItems();
    }).catch(error => ErrorHandler.enqueueError(error, enqueueSnackbar));
  }

  const handleChange = (name, value) => {
    if ((name === 'earnedPoints' || name === 'possiblePoints') && value.possiblePoints !== 0 &&  value.earnedPoints !== 0) {
      const numGrade = (value.earnedPoints / value.possiblePoints) * 100;
      if (numGrade >= 90) setGrade('A')
      else if (numGrade >= 80) setGrade('B')
      else if (numGrade >= 70) setGrade('C')
      else setGrade('F')
    }
  }

  const handleApprove = (e, form) => {
    setApproved(true)
  }

  const handleReject = (e, form) => {
    setApproved(false);
  }

  return (
      <Box paddingLeft={2} paddingTop={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography>{item.assignmentName}</Typography>
          <ButtonNoElevation variant="contained" onClick={handleReview}>
            Review
          </ButtonNoElevation>
        </Stack>
        <Modal open={open} onClose={handleClose} sx={{ overflow: 'auto' }}>
          <Slide in={open}>
            <StyledCard>
              <CardHeader title={`Review: ${item.assignmentName}`} />
              <CardContent>
                <Stack spacing={2}>
                  <Typography variant="body1">Instructions: {item.assignmentInstructions}</Typography>
                  <Box pb={2}>
                    Grade: {grade}
                  </Box>
                  <InputForm inputs={inputs.editInputs} onSubmit={handleSubmit} isEdit={true} handleChange={handleChange}
                    getButtons={(form) =>
                      <Stack direction="row" justifyContent="flex-end" spacing={1}>
                        <Button type="submit" variant="contained" onClick={(e) => handleReject(e, form)} color="error">Reject</Button>
                        <Button type="submit" variant="contained" onClick={(e) => handleApprove(e, form)}>Approve</Button>
                      </Stack>
                    } />
                </Stack>
              </CardContent>
            </StyledCard>
          </Slide>
        </Modal>
      </Box>
  )
}