import React from 'react';
import useInputs from "../../../hooks/useInputs";
import GenericInputLayout from "../../../layouts/GenericInput/GenericInputLayout";
import {useSnackbar} from "notistack";
import {AssignmentRoutineInputs} from "./AssignmentRoutineInputs";
import {
  CreateAssignment,
  DeleteAssignment,
  GetClassesAndAssignments,
  UpdateAssignment
} from "../../../api/AssignmentApi";
import {
  Box,
  List,
} from "@mui/material";
import * as AssignmentUtils from '../../../utils/AssignmentUtils'
import GroupedAssignments from "../../../components/Assignment/GroupedAssignments";
import useFilteredGroups from "../../../hooks/useFilteredGroups";

export default function AssignmentRoutinePage({ selectedClass }) {
  const inputs = useInputs(AssignmentRoutineInputs);
  const { items, setItems, filtered } = useFilteredGroups();
  const {enqueueSnackbar} = useSnackbar();

  const populate = React.useCallback(() => {
    GetClassesAndAssignments().then(json => {
      setItems(json);
    });
  }, [setItems])

  React.useEffect(() => {
    populate()
  }, [populate])

  const addOrUpdateAfter = (value) => {
    if (value.occurrenceType === 'template' && (!value.after || !value.after.id)) {
      items.forEach(it => {
        if (it.id === selectedClass.id) {
          it.groups.filter(day => day.name === value.dayOfWeek)
              .forEach(item => {
                const assignments = AssignmentUtils.getByOccurrenceType(item, 'template');
                const ordered = AssignmentUtils.orderAssignments(assignments);
                value.after = assignments.length > 0 ? ordered[assignments.length - 1].id : null;
              });
        }
      });
    } else if (value.occurrenceType === 'date') {
      value.after = null;
    }
  }

  const updateAssignments = (assignments, parent) => {
    const newItems = AssignmentUtils.updateGroupOrdering(items, assignments, parent);

    setItems([...newItems]);
  }

  const handleDelete = async (e, item) => {

    return DeleteAssignment(item.classes.id, item.id).then(() => {
      populate();
      enqueueSnackbar(`Successfully deleted material type: ${item.name}`, {variant: 'success'});
    });
  }

  const handleEdit = async (value) => {

    addOrUpdateAfter(value);

    return UpdateAssignment(selectedClass.id, value.id, value).then(json => {
      populate();
    });
  }

  const handleCreateAction = (payload) => {
    payload.classes = selectedClass;

    addOrUpdateAfter(payload);

    return CreateAssignment(selectedClass.id, payload)
  };

  return filtered.filter(it => it.id === selectedClass?.id)
      .map(it => (
          <Box paddingTop={1} key={it.id}>
            <GenericInputLayout
                header="Assignments By Course"
                newHeader="Add Assignment"
                inputs={inputs}
                createAction={handleCreateAction}
                onCreate={populate}
                items={it.groups}
                onEdit={handleEdit}
                getDisplayName={item => item.name}
                getListContent={(items, openModal) => (
                    <List>
                      {items.map(item =>
                          <GroupedAssignments key={item.id} onDelete={handleDelete} {...{item, updateAssignments, inputs, openModal}} />)}
                    </List>
                )}
            />
          </Box>
      ))
}