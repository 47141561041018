import React from 'react';
import Page from "../../components/Page";
import {useState} from "react";
import useInputs from "../../hooks/useInputs";
import GenericInputLayout from "../../layouts/GenericInput/GenericInputLayout";
import ItemUtils from "../../utils/ItemUtils";
import {useSnackbar} from "notistack";
import {CreateFacility, DeleteFacility, GetFacilities, UpdateFacility} from "../../api/FacilityApi";
import {FacilityInputs} from "./FacilityInputs";

export default function FacilityPage() {
  const [items, setItems] = useState([]);
  const inputs = useInputs(FacilityInputs);
  const {enqueueSnackbar} = useSnackbar();

  const populate = () => {
    GetFacilities().then(json => {
      setItems(json);
    })
  }

  React.useEffect(() => {
    populate()
  }, []);

  const handleDelete = async (e, item) => {

    return DeleteFacility(item.id).then(() => {
      setItems(ItemUtils.removeItem(items, item));
      enqueueSnackbar(`Successfully deleted material type: ${item.name}`, {variant: 'success'});
    });
  }

  const handleEdit = async (values) => {

    return UpdateFacility(values.id, values).then(json => {
      setItems(ItemUtils.replaceItem(items, json));
    });
  }

  return (
      <Page title="Facilities" sx={{ position: 'relative'}}>
        <GenericInputLayout
            header="Facility"
            newHeader="Add Facility"
            inputs={inputs}
            items={items}
            filterBy={'name'}
            createAction={CreateFacility}
            onCreate={populate}
            onEdit={handleEdit}
            onDelete={handleDelete}
            getDisplayName={item => item.name}
        />
      </Page>
  )
}