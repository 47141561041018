import React from "react";
import {Button, Card, CardContent, CardHeader, List, ListItem, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import {GenerateHolidays, GetHolidays} from "../../api/HolidayApi";
import {ErrorHandler} from "../../utils/ErrorHandler";

const AppWidgetHolidays = ({}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [items, setItems] = React.useState([]);

  const populate = () => {
    GetHolidays().then(json => {
      setItems(json);
    }).catch(error => ErrorHandler.enqueueError(error, enqueueSnackbar));
  }

  React.useEffect(() => {
    populate();
  }, []);

  return (
      <Card>
        <CardHeader title={"Holidays"} />
        <CardContent sx={{ pt: 1 }}>
          <List sx={{ height: 350, overflowY: 'scroll' }}>
            {items.map(item => (
                <ListItem key={item.id}>
                  {item.name}&nbsp;
                  <Typography variant="caption"> ({item.observedOn})</Typography>
                </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
  );
};

export default AppWidgetHolidays;