
export const GetSequenceAssignmentsByClass = (classId) => {
  return fetchApi(`/classes/${classId}/sequences/assignments`)
}

export const CreateSequence = (classId) => {
  return fetchApi(`/classes/${classId}/sequences`, { method: 'POST' })
}

export const DeleteSequence = (classId, id) => {
  return fetchApi(`/classes/${classId}/sequences/${id}`, { method: 'DELETE' })
}

export const CreateSequenceAssignment = (classId, item) => {
  return fetchApi(`/classes/${classId}/sequences/assignments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(item)
  });
}

export const UpdateSequenceAssignment = (classId, id, item) => {
  return fetchApi(`/classes/${classId}/sequences/assignments/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(item)
  });
}

export const DeleteSequenceAssignment = (classId, id) => {
  return fetchApi(`/classes/${classId}/sequences/assignments/${id}`, { method: 'DELETE' })
}

export const DeleteAllSequenceAssignments = (classId) => {
  return fetchApi(`/classes/${classId}/sequences`, { method: 'DELETE' })
}