import {Box, Card, CardContent, CardHeader, Modal, Slide, Stack, TextField, Typography} from "@mui/material";
import InputForm from "../../form/InputForm";
import React from "react";
import {styled} from "@mui/material/styles";
import GenericAddItem from "../../../layouts/GenericInput/GenericAddItem";

const StyledCard = styled(Card)({
  margin: '15px auto',
  width: 400,
  bgcolor: 'background.paper',
});

export default function AddSequenceAssignment({ inputs, title, open, many, seqIndex, onSubmit, handleModalClose }) {
  const [startNumber, setStartNumber] = React.useState(seqIndex);
  const [numSequences, setNumSequences] = React.useState(10)

  React.useEffect(() => {
    if (seqIndex) {
      setStartNumber(seqIndex + 2)
    }
  }, [seqIndex])

  const handleStartNumberChange = (e) => {
    setStartNumber(e.target.value)
  }

  const handleNumSeqChange = (e) => {
    setNumSequences(e.target.value)
  }

  const handleSubmit = (payload) => {
    payload.startNumber = startNumber
    payload.numSequences = numSequences;

    onSubmit(payload);
  }

  return (
      <Modal open={open} onClose={handleModalClose} sx={{ overflow: 'auto' }}>
        <Slide in={open}>
          <StyledCard>
            <CardHeader title={`Add ${title}`} />
            <CardContent>
              <Stack spacing={2}>
                { many && (
                    <Stack spacing={2}>
                      <Typography variant="subtitle2">Sequence Settings</Typography>
                      <TextField type="number" label="Start at Sequence" value={startNumber} onChange={handleStartNumberChange} />
                      <TextField type="number" label="How Many" value={numSequences} onChange={handleNumSeqChange} />
                    </Stack>
                )}
                <Stack spacing={2}>
                  { many && (
                      <Stack spacing={1}>
                        <Typography variant="subtitle2">Assignment Settings</Typography>
                        <Typography variant="caption">
                          use <code>{"{}"}</code> as a placeholder in the Name and Instructions field to
                          indicate where you want the counting value to be. For example: "Read Chapter <code>{"{}"}</code>"
                          would look like, "Read Chapter 4" if the Start Sequence above was at 4.
                        </Typography>
                      </Stack>
                  )}
                  <GenericAddItem inputs={inputs} createAction={handleSubmit} hasContainer={false} />
                </Stack>
              </Stack>
            </CardContent>
          </StyledCard>
        </Slide>
      </Modal>
  )
}