import React from 'react';
import {PendingWorkItem} from "./PendingWorkItem";
import {GenericListCollapse} from "../../GenericList/GenericListCollapse";

export function PendingUserClassItem({ item, getItems }) {

  return (
      <GenericListCollapse item={item}
                           getTitle={(it) => it.name}
                           getItems={it => it.items} >
        {( { item: item2 } ) => (
            <GenericListCollapse item={item2}
                                 getItems={(i) => i.items}
                                 getTitle={(i) => i.name} >
              {({ item: item2, odd }) => (
                  <PendingWorkItem item={item2} odd={odd} getItems={getItems} />
              )}
            </GenericListCollapse>
        )}
      </GenericListCollapse>
  )
}