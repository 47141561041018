
export const GetTerms = () => {
  return fetchApi("/terms")
}

export const GetTermDaysLeft = () => {
  return fetchApi("/terms/days-left")
}

export const CreateTerm = (subject) => {
  return fetchApi('/terms', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subject)
  });
}

export const UpdateTerm = (id, subject) => {
  return fetchApi(`/terms/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subject)
  });
}

export const DeleteTerm = (id) => {
  return fetchApi(`/terms/${id}`, { method: 'DELETE' })
}