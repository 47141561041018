
export const GetPendingEnrollments = () => {
  return fetchApi("/enrollments/pending")
}

export const CreatePendingEnrollment = (item) => {
  return fetchApi('/enrollments/pending', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(item)
  });
}

export const AcceptPendingEnrollment = (id, body) => {
  return fetchApi(`/enrollments/pending/${id}/accept`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
}

export const UpdatePendingEnrollment = (id, item) => {
  return fetchApi(`/enrollments/pending/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(item)
  });
}

export const DeletePendingEnrollment = (id) => {
  return fetchApi(`/enrollments/pending/${id}`, { method: 'DELETE' })
}