import {Navigate, useRoutes} from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";
import RootLayout from "./layouts/RootLayout";
import HomePage from "./pages/Home/HomePage";
import SubjectPage from "./pages/Subject/SubjectPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import MaterialPage from "./pages/Material/MaterialPage";
import CoursePage from "./pages/Course/CoursePage";
import MaterialTypePage from "./pages/MaterialType/MaterialTypePage";
import FacilityPage from "./pages/Facility/FacilityPage";
import TermPage from "./pages/Term/TermPage";
import AssignmentTypePage from "./pages/AssigmentType/AssignmentTypePage";
import InstructorPage from "./pages/Instructor/InstructorPage";
import ClassPage from "./pages/Class/ClassPage";
import DayOfWeekPage from "./pages/DayOfWeek/DayOfWeekPage";
import AssignmentRoutinePage from "./pages/Assigment/Routine/AssignmentRoutinePage";
import EnrollmentPage from "./pages/Enrollment/EnrollmentPage";
import AssignmentSequencePage from "./pages/Assigment/Sequence/AssignmentSequencePage";
import AssignmentDatePage from "./pages/Assigment/Date/AssignmentDatePage";
import CalendarPage from "./pages/Enrollment/Individual/CalendarPage";
import HolidayPage from "./pages/Holiday/HolidayPage";
import AssignmentPage from "./pages/Assigment/AssignmentPage";
import StudentClassesCalendarPage from "./pages/Enrollment/Individual/StudentClassesCalendarPage";

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <RootLayout />,
      children: [
        {path: '/', element: <Navigate to="/home"/>},
        { path: 'home', element: <HomePage />},
        { path: 'subjects', element: <SubjectPage />},
        { path: 'materials', element: <MaterialPage />},
        { path: 'material-types', element: <MaterialTypePage />},
        { path: 'courses', element: <CoursePage />},
        { path: 'classes', element: <ClassPage />},
        { path: 'assignment-types', element: <AssignmentTypePage />},
        { path: 'assignments', children: [
            {
              path: 'sequences',
              element: <AssignmentPage type="SEQUENCE" />
            },
            {
              path: 'routines',
              element: <AssignmentPage type="ROUTINE" />
            },
            {
              path: 'dates',
              element: <AssignmentPage type="DATE" />
            }
          ]
        },
        { path: 'day-of-week', element: <DayOfWeekPage />},
        { path: 'terms', element: <TermPage />},
        { path: 'instructors', element: <InstructorPage />},
        { path: 'facilities', element: <FacilityPage />},
        { path: 'holidays', element: <HolidayPage />},
        { path: 'enrollments', element: <EnrollmentPage /> },
        { path: 'enrollments/:id', element: <CalendarPage />},
        { path: 'enrollments/students/:id/events', element: <StudentClassesCalendarPage />},
        { path: '404', element: <NotFoundPage /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: 'login', element: <LoginPage />},
    { path: 'register', element: <RegisterPage />},
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}