import React from 'react';
import Page from "../../components/Page";
import {useState} from "react";
import useInputs from "../../hooks/useInputs";
import GenericInputLayout from "../../layouts/GenericInput/GenericInputLayout";
import ItemUtils from "../../utils/ItemUtils";
import {useSnackbar} from "notistack";
import {CreateTerm, DeleteTerm, GetTerms, UpdateTerm} from "../../api/TermApi";
import {TermInputs} from "./TermInputs";

export default function TermPage () {
  const [items, setItems] = useState([]);
  const inputs = useInputs(TermInputs);
  const {enqueueSnackbar} = useSnackbar();

  const populate = () => {
    GetTerms().then(json => {
      setItems(json);
    })
  }

  React.useEffect(() => {
    populate()
  }, []);

  const handleDelete = async (e, item) => {

    return DeleteTerm(item.id).then(() => {
      setItems(ItemUtils.removeItem(items, item));
      enqueueSnackbar(`Successfully deleted material type: ${item.term}`, {variant: 'success'});
    });
  }

  const handleEdit = async (values) => {

    return UpdateTerm(values.id, values).then(json => {
      setItems(ItemUtils.replaceItem(items, json));
    });
  }

  return (
      <Page title="Terms" sx={{ position: 'relative'}}>
        <GenericInputLayout
            header="Terms"
            newHeader="Add Term"
            inputs={inputs}
            items={items}
            filterBy={'term'}
            createAction={CreateTerm}
            onCreate={populate}
            onEdit={handleEdit}
            onDelete={handleDelete}
            getDisplayName={item => item.term}
        />
      </Page>
  )
}