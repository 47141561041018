import React, {useState} from "react";
import {Box, Collapse, ListItem, ListItemButton, Stack, Typography} from "@mui/material";
import AppWorkItemButtons from "./AppWorkItemButtons";
import {useTheme} from "@mui/material/styles";


export default function AppWorkItem({ item, generateWork }) {
  const [showButtons, setShowButtons] = useState(false);
  const theme = useTheme();

  return (
      <Stack>
        <ListItem sx={{
          overflow: "hidden",
          '& .MuiListItemSecondaryAction-root': {
            right: theme.spacing(1)
          },
          ...(showButtons && { backgroundColor: 'rgba(145, 158, 171, 0.08)' })
        }}
        onClick={() => setShowButtons(!showButtons)}
        disablePadding>
          <ListItemButton
              sx={{ paddingRight: '0 !important', ...(item.completed && { opacity: 0.6 })}}>
            <Stack>
              <Stack direction="row" spacing={1}>
                <Typography sx={{ ...(item.completed && { textDecoration: 'line-through' }) }}>
                  {item.assignmentName}
                </Typography>
              </Stack>
              <Typography variant="caption" color="gray">{item.assignmentInstructions}</Typography>
            </Stack>
          </ListItemButton>
        </ListItem>
        <Collapse in={showButtons}>
          <Box padding={1} sx={{ backgroundColor: 'rgba(145, 158, 171, 0.08)' }}>
            <AppWorkItemButtons item={item} generateWork={generateWork} />
          </Box>
        </Collapse>
      </Stack>
  )
}