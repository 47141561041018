import React from 'react';
import {FormProvider, useForm} from "react-hook-form";

import PropTypes from 'prop-types';
import {
  Button,
  Stack,
} from "@mui/material";
import RHFController from "./form-component/RHFController";
import {useLocation} from "react-router-dom";
import moment from "moment";

InputForm.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.exact({
    type: PropTypes.oneOf(['text', 'number', 'select', 'textarea', 'boolean', 'date', 'checkbox', 'autocomplete', 'choice', 'hidden']),
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    defaultValue: PropTypes.any,
    optionValue: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.string,
      children: PropTypes.array
    })),
    multiple: PropTypes.bool,
    getItems: PropTypes.func,
    validation: PropTypes.object,
    disabled: PropTypes.bool
  })).isRequired,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,

}

export default function InputForm ({ inputs, isEdit, onSubmit=()=>{}, submitText="submit", handleChange=()=>{}, getButtons = null }) {
  const form = useForm({ shouldUnregister: true });
  const { control, handleSubmit, setValue, setFocus, reset, watch } = form;
  const location = useLocation();

  React.useEffect(() => {
    const watched = watch((value, {name}) => handleChange(name, value, setValue))
    return () => watched.unsubscribe();
  }, [handleChange, watch]);

  React.useEffect(() => {
    reset();
  }, [location]);

  React.useEffect(() => {
    inputs.forEach(input => {
      if (input.type === 'date' && (input.value || input.defaultValue)) {
        setValue(input.name, moment(input.value || input.defaultValue));
      }
    });
  }, [inputs]);

  return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit((data, e) => onSubmit(data, setFocus, reset))}>
          <Stack justifyItems="flex-end" spacing={2}>
            { inputs.map(input => {
              const key = input.id ? `${input.id}-${input.name}` : `${input.type}-${input.name}`;

              return (
                  <RHFController
                      key={key}
                      {...{input, control, isEdit}}
                  />
              )}
            )}

            { getButtons != null ? getButtons(form) : (
                <Stack spacing={1} direction="row" justifyContent="space-between" paddingTop={1}>
                  <Button variant="text" onClick={() => reset()}>Reset</Button>
                  <Button type="submit" variant="contained" sx={{ paddingX: 4, boxShadow: 'none !important' }}>{submitText}</Button>
                </Stack>
            )}
          </Stack>
        </form>
      </FormProvider>
  )
}