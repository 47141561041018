import React, {useEffect, useMemo, useState} from "react";
import moment from 'moment'
import {Box, CircularProgress, Stack, Typography} from "@mui/material";
import {GetEnrollmentEvents} from "../../../api/EnrollmentApi";
import {useParams} from "react-router-dom";
import CalendarComponent from "../../../components/Calendar/CalendarComponent";


export default function CalendarPage() {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    GetEnrollmentEvents(id).then(json => {
      setItem(json);
      setEvents(json.events.map(it => {
        it.title = it.name
        it.start = moment(it.start)
        it.end = moment(it.end)

        return it;
      }));
    })
  }, [id]);

  const defaultDate = useMemo(() => {
    let date = null;
    if (item?.classes?.startDate) {
      const start = moment(moment(item.classes?.startDate));
      date = start.isSameOrBefore(moment()) ? moment() : start;
    }

    return date;
  }, [item?.classes?.startDate]);

  return (
      <Stack spacing={1} alignItems="center">
        { defaultDate && events.length > 0 ? (
            <>
              <Typography variant="h6">{item?.classes?.name}</Typography>
              <Box width="100%">
                <CalendarComponent events={events} startDate={defaultDate} />
              </Box>
            </>
        ) : (
            <Box sx={{ display: 'flex', mt: 3 }}>
              <CircularProgress />
            </Box>
        )}
      </Stack>
  )
}