import React from 'react';
import {Box, Collapse, List, ListItemButton, ListItemText, Stack} from "@mui/material";
import {ExpandLess} from "@mui/icons-material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export function GenericListCollapse({ item, getTitle, getItems, children }) {
  const [open, setOpen] = React.useState(false);

  return (
      <Box>
        <ListItemButton onClick={() => setOpen(!open)} sx={{ borderRadius: 1, paddingLeft: 1 }}>
          <Stack direction="row" spacing={1}>
            {open ? <ExpandLess /> : <KeyboardArrowRightIcon />}
            <ListItemText>{getTitle(item)} {getItems(item).length > 0 && `(${getItems(item).length})`}</ListItemText>
          </Stack>
        </ListItemButton>
        <Collapse in={open}>
          <List sx={{ pl: 3, width: '100%' }}>
            {getItems(item).map((it, index) => (
                <Box key={it.name}>
                  {children({ item: it, odd: index % 2 === 0 })}
                </Box>
            ))}
          </List>
        </Collapse>
      </Box>
  )
}