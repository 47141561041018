import {getBaseUrl} from "../utils/FetchUtil";

const login = (payload) => {

  return fetchApi(`/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const refreshToken = (payload) => {

  return fetch(`${getBaseUrl()}/refresh`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

export {
  login,
  refreshToken
}