import {useContext} from "react";
import {GlobalContext} from "../../providers/GlobalProvider";
import InstructorEnrollmentPage from "./Instructor/InstructorEnrollmentPage";
import StudentEnrollmentPage from "./Student/StudentEnrollmentPage";


export default function EnrollmentPage({  }) {
  const { user } = useContext(GlobalContext);

  return user.type === 'TEACHER' ? <InstructorEnrollmentPage /> : <StudentEnrollmentPage />
}