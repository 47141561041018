import FetchWrapper from "./FetchWrapper";

export const GetClassesAndAssignments = () => {
  return fetchApi(`/classes/assignments-by-day`)
}

export const GetPendingAssignments = () => {
  return fetchApi(`/classes/assignments/pending`)
};

export const GetClassesAndAssignmentsByDate = () => {
  return fetchApi(`/classes/assignments-by-date`)
}

export const GetAssignmentsByClass = (courseId) => {
  return fetchApi(`/classes/${courseId}/assignments`)
}

export const CreateAssignment = (id, subject) => {
  return fetchApi(`/classes/${id}/assignments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subject)
  });
}

export const UpdateAssignment = (courseId, id, subject) => {
  return fetchApi(`/classes/${courseId}/assignments/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subject)
  });
}

export const UpdateAssignments = (courseId, assignments) => {
  return fetchApi(`/classes/${courseId}/assignments`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(assignments)
  });
}

export const DeleteAssignment = (courseId, id) => {
  return fetchApi(`/classes/${courseId}/assignments/${id}`, { method: 'DELETE' })
}