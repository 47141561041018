import React, {useState} from "react";
import {Box, ListItemButton, ListItemIcon, ListItemText, ListSubheader} from "@mui/material";
import Iconify from "../Iconify";
import {Draggable} from "react-beautiful-dnd";
import GenericListItems from "../../layouts/GenericInput/GenericListItems";

export default function AssignmentListItem({ item, index, handleItemSelect, onDelete }) {



  return (
      <Draggable draggableId={item.id} index={index}>
        {(provided, snapshot) => (
            <Box ref={provided.innerRef}
                 {...provided.draggableProps}
                 {...provided.dragHandleProps}
                 sx={snapshot.isDragging ? { background: 'rgb(235,235,235)'} : {}}>
              <AssignmentListItemItem draggable={true} {...{item, handleItemSelect, onDelete}} />
            </Box>
        )}
      </Draggable>
  )
}

export function AssignmentListItemItem({ item, handleItemSelect, onDelete, disabled= false, draggable= true }) {


  return (
      <>
        <ListItemButton key={item.id} disabled={disabled} onClick={ (e) => !disabled && handleItemSelect(e, item) }>
          <ListItemIcon fontSize="large" sx={{ minWidth: 30 }}>
            { draggable ? (
                <Iconify icon="akar-icons:three-line-horizontal" />
            ) : (
                <Iconify icon="fluent-mdl2:radio-bullet" />
            )}
          </ListItemIcon>
          <ListItemText>{item.name}</ListItemText>
          {!disabled && onDelete && (
              <ListItemIcon onClick={(e) => onDelete(e, item)} sx={{ minWidth: 16 }}>
                <Iconify icon="ep:close-bold" />
              </ListItemIcon>
          )}
        </ListItemButton>
      </>
  )
}