import FetchWrapper from "./FetchWrapper";

export const GetMaterialTypes = () => {
  return fetchApi("/material-types")
}

export const CreateMaterialType = (subject) => {
  return fetchApi('/material-types', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subject)
  });
}

export const UpdateMaterialType = (id, subject) => {
  return fetchApi(`/material-types/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subject)
  });
}

export const DeleteMaterialType = (id) => {
  return fetchApi(`/material-types/${id}`, { method: 'DELETE' })
}