import React, {useState} from 'react';
import Page from "../../components/Page";
import useInputs from "../../hooks/useInputs";
import GenericInputLayout from "../../layouts/GenericInput/GenericInputLayout";
import ItemUtils from "../../utils/ItemUtils";
import {useSnackbar} from "notistack";
import {CreateClass, DeleteClass, GetClasses, UpdateClass} from "../../api/ClassApi";
import {ClassInputs} from "./ClassInputs";

export default function ClassPage() {
  const [items, setItems] = useState([]);
  const inputs = useInputs(ClassInputs);
  const {enqueueSnackbar} = useSnackbar();

  const populate = () => {
    GetClasses().then(json => {
      setItems(json);
    })
  }

  React.useEffect(() => {
    populate();
  }, []);

  const handleDelete = (e, item) => {
    return DeleteClass(item.id).then(() => {
      setItems(ItemUtils.removeItem(items, item));
      enqueueSnackbar(`Successfully deleted course: ${item.name}`, {variant: 'success'});
    });
  }

  const handleEdit = async (values) => {
    return UpdateClass(values.id, values).then(json => {
      setItems(ItemUtils.replaceItem(items, json));
    });
  }

  return (
      <Page title="Classes" sx={{ position: 'relative'}}>
        <GenericInputLayout
            header="Classes"
            newHeader="Add Class"
            inputs={inputs}
            items={items}
            filterBy={'name'}
            createAction={CreateClass}
            onCreate={populate}
            onEdit={handleEdit}
            onDelete={handleDelete}
            getDisplayName={item => item.name}
        />
      </Page>
  )
}