import React, {useState} from 'react';
import GenericEditModal from "../../../layouts/GenericInput/GenericEditModal";
import {Box, Button, Card, CardContent, CardHeader, IconButton, List, Stack} from "@mui/material";
import AssignmentList from "../AssignmentList";
import {CreateAssignment, DeleteAssignment, UpdateAssignment} from "../../../api/AssignmentApi";
import {
  CreateSequence, CreateSequenceAssignment, DeleteSequence,
  GetSequenceAssignmentsByClass
} from "../../../api/SequenceAssignmentApi";
import {useSnackbar} from "notistack";
import AddIcon from '@mui/icons-material/Add';
import {ErrorHandler} from "../../../utils/ErrorHandler";
import DeleteIcon from '@mui/icons-material/Delete';
import {
  AssignmentListItemItem
} from "../AssignmentListItem";
import AddSequenceAssignment from "./AddSequenceAssignment";
import AddSequenceButtons from "./AddSequenceButtons";
import {SearchContext} from "../../../providers/SearchProvider";

export default function SequenceAssignments({ selectedClass, inputs, reload, setReload }) {
  const [createModal, setCreateModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [filtered, setFiltered] = useState([]);
  const search = React.useContext(SearchContext);
  const {enqueueSnackbar} = useSnackbar();
  const [currentSequence, setCurrentSequence] = React.useState(null);
  const [addingSequence, setAddingSequence] = React.useState(false);
  const [many, setMany] = React.useState(false)
  const [seqIndex, setSeqIndex] = React.useState(1);
  const [currentAssignment, setCurrentAssignment] = React.useState(null)

  const populate = () => {
    GetSequenceAssignmentsByClass(selectedClass.id).then(json => {
      setItems(json);
    });
  }

  React.useEffect(() => {
    if (reload) {
      populate();
      setReload(false);
    }
  }, [reload]);

  React.useEffect(() => {
    if (search?.trim().length > 0) {
      setFiltered(items.map(item => {
        const assignments = item.assignments.filter(assign =>
            assign.name.toUpperCase().includes(search.toUpperCase()))

        return { ...item, assignments }
      }).filter(item => item.assignments.length > 0));
    } else {
      setFiltered(items);
    }
  }, [items, setItems, setFiltered, search])

  React.useEffect(() => {
    if (selectedClass?.id) {
      populate();
    }
  }, [selectedClass?.id])

  const handleAddAssignment = (seq, many) => {
    setCurrentSequence(seq);
    setMany(many)
    setCreateModal(true)
  }

  const updateAssignments = (assignments) => {
    const [seq] = assignments.map(it => it.sequence)
    items.forEach(it => {
      if (it.id === seq?.id) {
        it.assignments = assignments;
        it.assignments.forEach(assign => assign.classes = selectedClass);
      }
    });

    setItems([...items]);
  }

  const handleDelete = async (e, item) => {

    return DeleteAssignment(item.classes.id, item.id).then(() => {
      populate();
      enqueueSnackbar(`Successfully deleted material type: ${item.name}`, {variant: 'success'});
    });
  }

  const addOrUpdateAfter = (value) => {
    if (!value.after || !value.after.id) {
      items.forEach(it => {
        if (it.id === value.sequence?.id) {
          value.after = it.assignments.length > 0 ? it.assignments[it.assignments.length - 1].id : null;
        }
      });
    }
  }

  const handleSelect = (e, item) => {
    setCurrentAssignment(item);

    inputs.updateEditInputs(item);
    setEditModal(true);
  }

  const handleEdit = async (value) => {

    if (currentAssignment) {
      for (const prop in currentAssignment) {
        if (!value.hasOwnProperty(prop)) {
          value[prop] = currentAssignment[prop];
        }
      }
    }
    
    return UpdateAssignment(selectedClass.id, value.id, value).then(json => {
      populate();
    });
  }

  const handleCreateManySequences = (seq, index) => {
    setCurrentSequence(seq);
    setSeqIndex(index)

    handleAddAssignment(seq, true)
  }

  const handleCreateSequence = () => {
    setAddingSequence(true)

    CreateSequence(selectedClass.id)
        .then(() => {
          populate();
        })
        .catch(error => {
          ErrorHandler.enqueueError(error, enqueueSnackbar)
        })
        .finally(() => setAddingSequence(false));
  }

  const handleDeleteSequence = (item) => {
    DeleteSequence(selectedClass.id, item.id).then(json => {
      populate();
    }).catch(error => ErrorHandler.enqueueError(error, enqueueSnackbar));
  }

  const handleCreateAction = (payload) => {
    payload.classes = selectedClass;
    if (currentSequence) {
      payload.sequence = {id: currentSequence.id};
    }
    payload.many = many;

    addOrUpdateAfter(payload);

    return (many ? CreateSequenceAssignment(selectedClass.id, payload) : CreateAssignment(selectedClass.id, payload))
        .then(() => {
          populate();
          setCreateModal(false);
        });
  };

  return (
      <Box>
        {filtered.map((item, i) => (
            <Box key={item.id}>
              <Card sx={{ marginTop: 1 }}>
                <CardHeader title={
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Box sx={{ opacity: item.completed ? 0.5 : 1 }}>{`Sequence ${i + 1}`} {item.completed && '(Completed)'}</Box>
                    {!item.completed && (
                        <IconButton size="small" onClick={() => handleDeleteSequence(item)}>
                          <DeleteIcon />
                        </IconButton>
                    )}
                  </Stack> } />
                <CardContent sx={{ paddingTop: 0 }}>
                  <List>
                    {item.completed || search ? (
                        <Box py={1}>
                          { item.assignments.map((assignment) => (
                              <AssignmentListItemItem
                                  key={assignment.id}
                                  item={assignment}
                                  disabled={item.completed}
                                  draggable={false}
                                  onDelete={handleDelete}
                                  handleItemSelect={handleSelect}
                              />
                          ))}
                        </Box>
                    ) : (
                        <AssignmentList key={item.id} onDelete={handleDelete} onSelect={handleSelect} assignments={item.assignments} {...{updateAssignments}} />
                    )}
                  </List>
                  {!item.completed && (
                      <Button startIcon={<AddIcon />} onClick={() => handleAddAssignment(item, false)}>
                        Add Assignment
                      </Button>
                  )}
                </CardContent>
              </Card>
              {!addingSequence && i === items.length - 1 && (
                  <AddSequenceButtons
                      handleSingle={() => handleCreateSequence()}
                      handleMany={() => handleCreateManySequences(item, i)} />
              )}
            </Box>
        ))}

        {!addingSequence && items.length === 0 && (
            <AddSequenceButtons
                handleSingle={() => handleCreateSequence()}
                handleMany={() => handleCreateManySequences()} />
        )}
        <AddSequenceAssignment inputs={inputs.inputs}
                               title={many ? 'Many Sequences' : 'Assignment'}
                               open={createModal} handleModalClose={() => setCreateModal(false)}
                               {...{many, seqIndex}}
                               onSubmit={handleCreateAction} />
        <GenericEditModal inputs={inputs.editInputs}
                          open={editModal}
                          onSubmit={handleEdit}
                          handleModalClose={() => setEditModal(false)} />
      </Box>
  )
}