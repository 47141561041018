import React, {useState} from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {ErrorHandler} from "../../utils/ErrorHandler";
import {useSnackbar} from "notistack";
import AssignmentListItem from "./AssignmentListItem";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {UpdateAssignments} from "../../api/AssignmentApi";
import * as AssignmentUtils from "../../utils/AssignmentUtils";
import GenericListItems from "../../layouts/GenericInput/GenericListItems";

export default function AssignmentList({ assignments, updateAssignments, onDelete, onSelect }) {
  const {enqueueSnackbar} = useSnackbar();

  const handleDragEnd = ({source, destination}) => {
    const after = AssignmentUtils.updateOrdering(assignments, source, destination);

    if (after.updateItems) {
      assignments = [...after.assignments];

      const classId = after.updateItems[0].classes.id;

      UpdateAssignments(classId, after.updateItems).then(() => {
        updateAssignments([...after.assignments]);
      }).catch(error => {
        ErrorHandler.enqueueError(error, enqueueSnackbar)
      });
    }
  }

  const handleDelete = (e, assignment) => {
    e.stopPropagation();

    onDelete(e, assignment).catch(error => ErrorHandler.enqueueError(error, enqueueSnackbar));
  }

  return (
      <>
        <DragDropContext onDragEnd={async (it) => handleDragEnd(it)}>
          <Droppable droppableId="droppable-list">
            {provided => (
                <List ref={provided.innerRef} {...provided.droppableProps}>
                  {assignments?.length > 0
                      ? (assignments.map((assignment, index) => (
                          <AssignmentListItem
                              key={assignment.id}
                              item={assignment}
                              index={index}
                              handleItemSelect={onSelect}
                              onDelete={handleDelete}
                          />
                      ))) : (
                          <ListItem>
                            <ListItemText>No assignments</ListItemText>
                          </ListItem>
                      )}
                  {provided.placeholder}
                </List>
            )}
          </Droppable>
        </DragDropContext>
      </>
  )
}