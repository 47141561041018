import {GetMaterialTypes} from "../../api/MaterialTypeApi";
import {GetSubjects} from "../../api/SubjectApi";

export const MaterialInputs = [
  { name: 'name', label: "Name", type: 'text', defaultValue: '', validation: { required: "Please enter a title" } },
  { name: 'materialType', label: 'Material Type', getItems: GetMaterialTypes, optionValue: 'name',
    validation: { required: "Select valid option", validate: val => val.name ? true : 'Please choose a material type' },
    type: 'autocomplete',
    defaultValue: { name: '' }
  },
  { name: 'subjects', label: 'Subjects', getItems: GetSubjects, optionValue: 'name',
    type: 'autocomplete',
    multiple: true,
    defaultValue: []
  }
];