import React from 'react';
import PropTypes from "prop-types";
import AppTasks from "../AppTasks/AppTasks";
import {
  Card,
  CardContent,
  List,
} from "@mui/material";
import AppWorkClass from "./AppWorkClass";

AppTasks.propTypes = {
  work: PropTypes.array.isRequired,
};

export default function AppWork({ work, generateWork }) {
  const [groupedWork, setGroupedWork] = React.useState({});

  React.useEffect(() => {
    const groups = {};
    work.forEach(item => {
      const name = item.className;
      if (groups[name]) {
        groups[name].push(item)
      } else {
        groups[name] = [item];
      }
    })

    setGroupedWork(groups)
  }, [work, setGroupedWork])

  return (
      <Card>
        <CardContent>
          <List>
            {Object.keys(groupedWork).map((item, idx) => (
                <React.Fragment key={idx}>
                  <AppWorkClass className={item} items={groupedWork[item]} generateWork={generateWork} />
                  {/*{idx + 1 < Object.keys(groupedWork).length && (
                      <Divider component="li" sx={{ marginY: 1 }} />
                  )}*/}
                </React.Fragment>
            ))}
          </List>
        </CardContent>
      </Card>
  )
}