import React from 'react';
import useInputs from "../../../hooks/useInputs";
import {AssignmentSequenceInputs} from "./AssignmentSequenceInputs";
import {
  Box,
} from "@mui/material";
import SequenceAssignments from "../../../components/Assignment/Sequence/SequenceAssignments";


export default function AssignmentSequencePage({ selectedClass, reload, setReload }) {
  const inputs = useInputs(AssignmentSequenceInputs);

  React.useEffect(() => {
    if (selectedClass?.id) {
      if (inputs.inputs) {
        inputs.inputs.map((input) => {
          if (input.name === 'sequence') {
            input.getItemsParams = [selectedClass.id];
          }

          return input;
        });

        inputs.setInputs([...inputs.inputs ])
      }
    }
  }, [selectedClass?.id]);

  return (
      <Box marginTop={1}>
        <SequenceAssignments {...{inputs, selectedClass, reload, setReload}} />
      </Box>
  )
}