
export const GetNotifications = () => {
  return fetchApi("/notifications")
}

export const GetRecentNotifications = () => {
  return fetchApi("/notifications/recent")
}

export const CreateNotification = (item) => {
  return fetchApi('/notifications', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(item)
  });
}

export const UpdateNotification = (id, item) => {
  return fetchApi(`/notifications/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(item)
  });
}

export const DeleteNotification = (id) => {
  return fetchApi(`/notifications/${id}`, { method: 'DELETE' })
}

export const MarkAllAsRead = () => {
  return fetchApi('/notifications/mark-as-read', { method: 'POST' })
}