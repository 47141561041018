import React, {useState} from "react";
import {
  Box,
  Collapse,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import AssignmentList from "./AssignmentList";
import {SearchContext} from "../../providers/SearchProvider";
import {AssignmentListItemItem} from "./AssignmentListItem";

export default function GroupedAssignments({ item, inputs, updateAssignments, onDelete, openModal }) {
  const [open, setOpen] = useState(false);
  const search = React.useContext(SearchContext)

 const handleUpdateAssignments = (assignments) => {
   updateAssignments(assignments, item)
 }

  const handleItemSelect = (e, assignment) => {
    inputs.updateEditInputs(assignment);

    openModal(true);
  }

  return (
      <>
        <ListItemButton onClick={() => setOpen(!open)}>
          <ListItemText>{item.name} ({item.assignments.length})</ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open}>
          <>
            { search ? (
                <Box py={1}>
                  { item.assignments.map(assign => (
                      <AssignmentListItemItem key={assign.id} item={assign} onDelete={onDelete} handleItemSelect={handleItemSelect} draggable={false} />
                  ))}
                </Box>
            ) : (
                <AssignmentList assignments={item.assignments} onSelect={handleItemSelect} updateAssignments={handleUpdateAssignments} {...{onDelete}} />
            )}
          </>
        </Collapse>
      </>
  )
}