import React, {useState} from "react";
import {useSnackbar} from "notistack";
import Page from "../../../components/Page";
import {DeleteEnrollment} from "../../../api/EnrollmentApi";
import {AcceptPendingEnrollment, DeletePendingEnrollment} from "../../../api/PendingEnrollmentApi";
import {
  Button,
  Card,
  CardContent,
  CardHeader, Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon, ListItemText, Stack, Typography
} from "@mui/material";
import {GetClassesAndEnrollments} from "../../../api/ClassApi";
import ClassEnrollmentList from "../../../components/ClassEnrollment/ClassEnrollmentList";
import Iconify from "../../../components/Iconify";
import {GenerateWork} from "../../../api/WorkApi";
import {ErrorHandler} from "../../../utils/ErrorHandler";

export default function StudentEnrollmentPage() {
  const [items, setItems] = useState([]);
  const {enqueueSnackbar} = useSnackbar();

  const populate = () => {
    GetClassesAndEnrollments("STUDENT").then(json => {
      setItems(json);
    })
  }

  React.useEffect(() => {
    populate()
  }, []);

  const handleGenerateWork = (e, item) => {
    GenerateWork([item.id]).then(json => {
      enqueueSnackbar('Successfully generated work', {variant: 'success'});
    }).catch(error => ErrorHandler.enqueueError(error, enqueueSnackbar));
  }

  const handleDelete = async (e, item) => {
    const operation = item.email ? DeletePendingEnrollment : DeleteEnrollment;


    return operation(item.id).then(() => {
      populate();
      enqueueSnackbar('Successfully deleted enrollment', {variant: 'success'});
    });
  }

  const handleAccept = (e, item) => {
    console.log(item)
    AcceptPendingEnrollment(item.id, { token: item.verifyToken }).then(json => {
      populate();
    })
  }

  return (
      <Page title="Enrollments" sx={{ position: 'relative'}}>
        <Grid container spacing={2}>
          {items.flatMap(it => it.enrollments).length > 0 && (
              <Grid item xs>
                <Card>
                  <CardHeader title="Enrollments" />
                  <CardContent>
                    <List>
                      {items.filter(it => it.enrollments.length > 0).map(item => (
                          <ListItemButton key={item.classes.id} sx={{ borderRadius: 1.25 }}>
                            <ListItemText>
                              {item.classes.name}&nbsp;
                              <Typography variant="caption">
                                ({item.classes.owner.firstName} {item.classes.owner.lastName})
                              </Typography>
                            </ListItemText>
                            <ListItemIcon sx={{ marginRight: 1 }} onClick={(e) => handleGenerateWork(e, item.enrollments[0])}>
                              <Button variant={"outlined"}>Generate Work</Button>
                            </ListItemIcon>
                            <ListItemIcon onClick={(e) => handleDelete(e, item.enrollments[0])}>
                              <IconButton size="small">
                                <Iconify icon="ep:close-bold" />
                              </IconButton>
                            </ListItemIcon>
                          </ListItemButton>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
          )}
          {items.flatMap(it => it.pending).length > 0 && (
              <Grid item xs={6}>
                <Card>
                  <CardHeader title="Pending Enrollments" />
                  <CardContent>
                    <List>
                      {items.filter(it => it.pending.length > 0).map(item => (
                          <ListItemButton key={item.classes.id} sx={{ borderRadius: 1.25 }}>
                            <ListItemText>
                              {item.classes.name}&nbsp;
                              <Typography variant="caption">
                                ({item.classes.owner.firstName} {item.classes.owner.lastName})
                              </Typography>
                            </ListItemText>
                            <>
                              <ListItemIcon onClick={(e) => handleAccept(e, item.pending[0])}>
                                <IconButton size="small">
                                  <Iconify icon="gridicons:checkmark" />
                                </IconButton>
                              </ListItemIcon>
                              <ListItemIcon onClick={(e) => handleDelete(e, item.pending[0])}>
                                <IconButton size="small">
                                  <Iconify icon="ep:close-bold" />
                                </IconButton>
                              </ListItemIcon>
                            </>
                          </ListItemButton>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
          )}
        </Grid>
      </Page>
  )
}