import {GetCourses} from "../../api/CourseApi";
import {GetTerms} from "../../api/TermApi";
import {GetFacilities} from "../../api/FacilityApi";
import {GetMaterials} from "../../api/MaterialApi";
import {GetDaysOfWeek} from "../../api/DayOfWeekApi";

export const ClassInputs = [
  { name: 'name', label: "Name", validation: { required: "Please enter a name" }, type: 'text', defaultValue: '' },
  { name: 'startDate', label: "Start Date", type: 'date', defaultValue: '', validation: { required: "Please enter a date" } },
  { name: 'endDate', label: "End Date", type: 'date', defaultValue: '', validation: { required: "Please enter a date" } },
  {
    name: 'course',
    label: 'Course',
    getItems: GetCourses,
    optionValue: 'name',
    validation: {
      required: true,
      validate: val => val.name ? true : 'Please choose a Course' },
    type: 'autocomplete',
    defaultValue: { name: '' }
  },
  {
    name: 'term',
    label: 'Term',
    getItems: GetTerms,
    optionValue: 'term',
    validation: {
      required: true,
      validate: val => val.term ? true : 'Please choose a Term' },
    type: 'autocomplete',
    defaultValue: { term: '' }
  },
  {
    name: 'daysOfWeek',
    label: 'Days of Week',
    getItems: GetDaysOfWeek,
    optionValue: 'name',
    type: 'autocomplete',
    multiple: true,
    defaultValue: []
  },
  {
    name: 'facility',
    label: 'Facility',
    getItems: GetFacilities,
    optionValue: 'name',
    type: 'autocomplete',
    defaultValue: { name: '' }
  }
]
