import React from 'react';
import Page from "../../components/Page";
import {useState} from "react";
import useInputs from "../../hooks/useInputs";
import GenericInputLayout from "../../layouts/GenericInput/GenericInputLayout";
import ItemUtils from "../../utils/ItemUtils";
import {useSnackbar} from "notistack";
import {CreateMaterialType, DeleteMaterialType, GetMaterialTypes, UpdateMaterialType} from "../../api/MaterialTypeApi";
import {MaterialTypeInputs} from "./MaterialTypeInputs";

export default function MaterialTypePage() {
  const [items, setItems] = useState([]);
  const inputs = useInputs(MaterialTypeInputs);
  const {enqueueSnackbar} = useSnackbar();

  const populate = () => {
    GetMaterialTypes().then(json => {
      setItems(json);
    })
  }

  React.useEffect(() => {
    populate()
  }, []);

  const handleDelete = async (e, item) => {

    return DeleteMaterialType(item.id).then(() => {
      setItems(ItemUtils.removeItem(items, item));
      enqueueSnackbar(`Successfully deleted material type: ${item.name}`, {variant: 'success'});
    });
  }

  const handleEdit = async (values) => {

    return UpdateMaterialType(values.id, values).then(json => {
      setItems(ItemUtils.replaceItem(items, json));
    });
  }

  return (
      <Page title="Material Types" sx={{ position: 'relative'}}>
        <GenericInputLayout
            header="Material Types"
            newHeader="Add Material Type"
            inputs={inputs}
            items={items}
            filterBy={'name'}
            createAction={CreateMaterialType}
            onCreate={populate}
            onEdit={handleEdit}
            onDelete={handleDelete}
            getDisplayName={item => item.name}
        />
      </Page>
  )
}