import {GetClasses} from "../../../api/ClassApi";

export const InstructorEnrollmentInputs = [
  { name: 'email', label: "Email", type: 'text', defaultValue: '', validation: { required: true} },
  {
    name: 'classes',
    label: 'Class',
    getItems: GetClasses,
    optionValue: 'name',
    validation: {
      required: true,
      validate: val => val.name ? true : 'Please choose a Class' },
    type: 'autocomplete',
    defaultValue: { name: '' }
  },
];