import {GetMaterials} from "../../api/MaterialApi";
import {GetSubjects} from "../../api/SubjectApi";

export const CourseInputs = [
  { name: 'name', label: "Name", validation: { required: true }, type: 'text', defaultValue: '' },
  { name: 'description', label: "Description", type: 'textarea', defaultValue: '' },
  { name: 'numberOfDays', label: "Number of Days", type: 'text', defaultValue: '' },
  { name: 'creditHours', label: "Credit Hours", type: 'text', defaultValue: '' },
  { name: 'grade', label: "Suggested Grade Level", type: 'select', defaultValue: '', options: [
      { label: "Preschool", value: '-1' },
      { label: "Kindergarten", value: '0' },
      { label: "1st", value: '1' },
      { label: "2nd", value: '2' },
      { label: "3rd", value: '3' },
      { label: "4th", value: '4' },
      { label: "5th", value: '5' },
      { label: "6th", value: '6' },
      { label: "7th", value: '7' },
      { label: "8th", value: '8' },
      { label: "9th", value: '9' },
      { label: "10th", value: '10' },
      { label: "11th", value: '11' },
      { label: "12th", value: '12' },
    ]
  },
  {
    name: 'materials',
    label: 'Materials',
    getItems: GetMaterials,
    optionValue: 'name',
    type: 'autocomplete',
    multiple: true,
    defaultValue: []
  },
  {
    name: 'subject',
    label: 'Subject',
    getItems: GetSubjects,
    optionValue: 'name',
    validation: {
      required: true,
      validate: val => val.name ? true : 'Please choose a subject' },
    type: 'autocomplete',
    defaultValue: { name: '' }
  }
];