import {Box, Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import React from "react";

export default function AddSequenceButtons({ handleSingle, handleMany }) {
  return (
      <Box>
        <Button sx={{ marginLeft: 1, marginTop: 2 }} startIcon={<AddIcon />} onClick={handleSingle}>
          Add
        </Button>
        <Button sx={{ marginLeft: 1, marginTop: 2 }} startIcon={<PlaylistAddIcon />} onClick={handleMany}>
          Add Many
        </Button>
      </Box>
  )
}