import React, {useState} from "react";
import Timecode from "react-timecode";

export default function AppWorkTimer({item, timerActive, time, handleTimeUpdate}) {

  React.useEffect(() => {
    handleTimeUpdate(item.progress?.duration || 0);
  }, [item.progress?.duration]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (timerActive) {
        handleTimeUpdate(time + 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timerActive, time])

  return (
      <>
        <Timecode time={time * 1000} format="HH:mm:ss" />
      </>
  )
}